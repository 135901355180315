
const randomNumberCache = {};

export function isPortraitOrientation() {
    var orientation;
    if (window.orientation !== undefined) {
        orientation = window.orientation;
        return orientation === 0 || orientation === 180 ? true : false;
    } else {
        orientation = window.screen.orientation.type;
        return orientation === "portrait-primary" ? true : false;
    }
}


export function isUserAgentABrowser() {

    function init(_dataos, _databrowser) {
        var agent = header.join(' ');
        var os = matchItem(agent, _dataos);
        var browser = matchItem(agent, _databrowser);

        return {
            os: os,
            browser: browser,
        }
    }

    function matchItem(string, data) {
        var i = 0,
            j = 0,
            regex,
            regexv,
            match,
            matches,
            version;

        for (i = 0; i < data.length; i += 1) {
            regex = new RegExp(data[i].value, 'i');
            match = regex.test(string);
            if (match) {
                regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                matches = string.match(regexv);
                version = '';
                if (matches) { if (matches[1]) { matches = matches[1]; } }
                if (matches) {
                    matches = matches.split(/[._]+/);
                    for (j = 0; j < matches.length; j += 1) {
                        if (j === 0) {
                            version += matches[j] + '.';
                        } else {
                            version += matches[j];
                        }
                    }
                } else {
                    version = '0';
                }
                return {
                    name: data[i].name,
                    version: parseFloat(version)
                };
            }
        }
        return { name: 'unknown', version: 0 };
    }

    var header = [navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera, navigator.platform];
    var dataos = [
        { name: 'Android', value: 'Android', version: 'Android' },
        { name: 'iPhone', value: 'iPhone', version: 'OS' },
        { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
        { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
        { name: 'iPad', value: 'iPad', version: 'OS' },
        { name: 'Kindle', value: 'Silk', version: 'Silk' },
        { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
        { name: 'Palm', value: 'Palm', version: 'PalmOS' },
        { name: 'Windows', value: 'Win', version: 'NT' },
        { name: 'Macintosh', value: 'Mac', version: 'OS X' },
        { name: 'Linux', value: 'Linux', version: 'rv' }
    ];

    var databrowser = [
        { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
        { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
        { name: 'Safari', value: 'Safari', version: 'Version' },
        { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
        { name: 'Opera', value: 'Opera', version: 'Opera' },
        { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
        { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
    ];

    var desktops = ['Windows', 'Macintosh', 'Linux'];


    var e = init(dataos, databrowser);

    return isItemInArray(desktops, e.os.name);

}

export function getIndexOfItemInArray (array, value) {
    return array.indexOf(value);
}

export function isItemInArray (array, value) {
    return getIndexOfItemInArray(array, value) > -1;
}


export function getScaleMultiplier(baseScale, newScale) {
    return baseScale >= newScale ?  baseScale / newScale : newScale / baseScale;
}


export function getDescaleMultiplier(baseScale, newScale) {
    return baseScale <= newScale ? baseScale / newScale : newScale / baseScale;
}


export function injectStyle(style, title) {
    // inject style rule
    // https://gist.github.com/yamadayuki/f1ea9ccacad7f1c140457b5877fb54cc
    // https://developer.mozilla.org/en-US/docs/Web/API/CSSStyleSheet/insertRule

    if (arguments.length < 2) {
        var title = '';
    }

    const styleElement = document.createElement('style');
    styleElement.id = title;

    var styleSheet = null;

    document.head.appendChild(styleElement);

    styleSheet = styleElement.sheet;

    if (typeof style === "string") {
        styleSheet.insertRule(style, styleSheet.cssRules.length);
    } else {
        for (var i = 0; i < style.length; i++) {
            styleSheet.insertRule(style[i], styleSheet.cssRules.length);
        }
    }
}

export function generateRandomNumber() {
    // https://stackoverflow.com/questions/1527803/generating-random-whole-numbers-in-javascript-in-a-specific-range
    const value = Math.floor(Math.random() * 1000000);

    if (randomNumberCache.hasOwnProperty(value)) {
        return generateRandomNumber();
    } else {
        randomNumberCache[value] = '';
        return value;
    }
}

export function removeStyle(title) {
    var style = document.getElementById(title);
    if (style !== null && style !== undefined) {
        style.parentNode.removeChild(style);
    }
}

export function roundNumber(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}
