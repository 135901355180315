
import ConstructHome from './landing_page.js';
import './App.css';

function App() {
  return (
    <div className="App">
      <ConstructHome />    
    </div>
  );
}

export default App;
