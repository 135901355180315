
const React = require('react');

const {
    isMobile,
    isIOS,
} = require("react-device-detect");

const desktopControlHeight = 0;

const currentYear = new Date().getFullYear() - 1;

const {
    getScaleMultiplier,
    getDescaleMultiplier,
    isPortraitOrientation,
    isUserAgentABrowser,
    injectStyle,
    removeStyle,
    generateRandomNumber,
    roundNumber,

} = require('./helpers');

const {AppBar, } = require('material-ui-v0/AppBar');
const {Toolbar, ToolbarGroup, } = require('material-ui-v0/Toolbar');
const {Card, CardMedia, CardTitle, CardText, } = require('material-ui-v0/Card');
const {List, ListItem, } = require('material-ui-v0/List');
const FlatButton = require('material-ui-v0/FlatButton').default;
const Dialog = require('material-ui-v0/Dialog').default;
const Divider = require('material-ui-v0/Divider').default;
const Subheader = require('material-ui-v0/Subheader').default;
const Avatar = require('material-ui-v0/Avatar').default;
const IconMenu = require('material-ui-v0/IconMenu').default;
const IconButton = require('material-ui-v0/IconButton').default;

const MoreVertIcon = require('material-ui-v0/svg-icons/navigation/more-vert').default;

const getMuiTheme = require('material-ui-v0/styles/getMuiTheme').default;
const MuiThemeProvider = require('material-ui-v0/styles/MuiThemeProvider').default;

// component theme style constants, custom style properties here
const muiTheme = getMuiTheme({
    palette: {textColor: 'rgba(2, 30, 81, 0.87)'},
});


function createConstructionHomeStyles (devicePixelRatio, isPortrait, staticUrl) {
    if (arguments.length === 0) {
        var devicePixelRatio = 1;
        var isPortrait = true;
        var staticUrl = '';
    }

    var containerStyle, imgRadius, menuHeight, appBarHeight, scalar, currentDevicePixelRatio;
    if (devicePixelRatio <= 1 || isUserAgentABrowser()) {
        currentDevicePixelRatio = 1;

        if (isUserAgentABrowser()) {
            scalar = 1;
        } else {
            scalar = getScaleMultiplier(currentDevicePixelRatio, devicePixelRatio);
        }

        appBarHeight = isUserAgentABrowser() || isPortrait ? desktopControlHeight : 32;

        menuHeight = 56;

        if (isUserAgentABrowser() || isPortrait) {
            containerStyle = {};
        } else {
            containerStyle = {};
        }
        imgRadius = 300 * scalar;
    } else if (devicePixelRatio <= 2) {
        currentDevicePixelRatio = 2;
        scalar = getDescaleMultiplier(currentDevicePixelRatio, devicePixelRatio);

        appBarHeight = 144;

        menuHeight = 0;

        if (isPortrait) {
            containerStyle = {};
        } else {
            containerStyle = {};
        }
        imgRadius = isPortrait ? 400 * scalar : 200 * scalar;
    } else if (devicePixelRatio <= 3) {
        currentDevicePixelRatio = 3;
        scalar = getDescaleMultiplier(currentDevicePixelRatio, devicePixelRatio);

        appBarHeight = isPortrait ? 144 : 80;

        menuHeight = 0;

        if (isPortrait) {
            containerStyle = {};
        } else {
            containerStyle = {};
        }
        imgRadius = isPortrait ? 400 * scalar : 200 * scalar;
    } else if (devicePixelRatio > 3) {
        currentDevicePixelRatio = 3;
        scalar = getDescaleMultiplier(currentDevicePixelRatio, devicePixelRatio);

        appBarHeight = 144;

        menuHeight = 0;

        if (isPortrait) {
            containerStyle = {};
        } else {
            containerStyle = {};
        }
        imgRadius = isPortrait ? 400 * scalar : 200 * scalar;

    }

    const about_us_image_url = "/about_us.png";

    const toolbarSize = menuHeight + appBarHeight;

    const bodyClassName = 'projectDialog';

    const dialogScrollCSS = [
        "." + bodyClassName + "::-webkit-scrollbar{width: 8px;}",
        "." + bodyClassName + "::-webkit-scrollbar-track{background:rgb(244,244,244);}",
        "." + bodyClassName + "::-webkit-scrollbar-thumb{background:rgb(188,188,188);}"
    ];

    return {
        constants: {
            imgRadius: imgRadius,
            appBarHeight: appBarHeight,
            menuHeight: menuHeight,
            toolbarSize: toolbarSize,
            bodyClassName: bodyClassName,
        },
        dialogScrollCSS: dialogScrollCSS,
        iconButtonStyle: {
            width: 96 + 'px',
            height: 96 + 'px',
        },
        iconButtonIconStyle: {
            width: 72 + 'px',
            height: 72 + 'px',
        },
        bodyContent: {
            top: 0 + 'px',
            position: 'absolute',
        },
        textFlex: {
            flex: isPortrait ? 0.5 : 1,
        },
        imageFlex: {
            display: 'flex',
            flex: isPortrait ? 10 : 3,
            overflow: 'hidden',
            flexBasis: 'auto',
        },
        headerStyle: {
            color: muiTheme.palette.textColor,
            fontFamily: muiTheme.fontFamily,
            fontSize: isUserAgentABrowser() || isPortrait ? currentDevicePixelRatio * scalar * 2 + 'em' : currentDevicePixelRatio * scalar * 1 + 'em',
            textAlign: 'center',
        },
        containerStyle: containerStyle,
        imgStyleLogo: {
            maxHeight: 70 + 'px',
        },
        imgStyleLogoMobile: {
            maxHeight: toolbarSize + 'px',
        },
        appBarComponentToolbarStyle: {
            marginLeft: 0 + 'px',
        },
        appBarComponentWrapperStyle: {
            padding: '0px 24px',
        },
        imgStyleSunset: {
            margin: 'auto',
            width: 100 + '%',
            height: 'calc(100vh)',
            position: 'absolute',
            left: '0px',
        },
        imgStyleSunsetMobile: {
            margin: 'auto',
            width: 100 + '%',
            marginTop: (-toolbarSize) + 'px',
            height: 'calc(100vh + ' + toolbarSize + 'px)',
            position: 'absolute',
            objectFit: 'cover',
            objectPosition: 'bottom',
            left: '0px',
        },
        imgStyleAbout: {
            width: 100 + '%',
        },
        buttonLabelStyle: {
            fontSize: 38 + 'px',
            textAlign: 'center',
        },
        buttonLabelStyleMobile: {
            fontSize: 48 + 'px',
            lineHeight: 64 + 'px',
            textAlign: 'center',
            textDecoration: 'underline',
        },
        buttonLabelStyleMedium: {
            fontSize: 34 + 'px',
            textAlign: 'center',
        },
        buttonLabelStyleSmall: {
            fontSize: 28 + 'px',
            textAlign: 'center',
        },
        buttonStyle: {
            flex: 1,
            color: 'white',
        },
        headerButtonStyle: {
            width: 100 + '%',
            color: 'white',
        },
        headerButtonStyleMobile: {
            width: 100 + '%',
            color: 'white',
            height: 'unset',
            lineHeight: 'unset',
        },
        cardRootStyle: {
            flex: 1,
        },
        cardTitleStyle: {
            fontSize: 18 + 'px',
        },
        cardTitleStyleMobile: {
            fontSize: 38 + 'px',
        },
        cardSubTitleStyleMobile: {
            fontSize: 24 + 'px',
        },
        cardTitleStyleMedium: {
            fontSize: 16 + 'px',
        },
        projectCardTitleStyleMedium: {
            fontSize: 14 + 'px',
        },
        cardStyle: {
            width: 70 + '%',
            margin: '0% 15%',
            height: 100 + '%',
        },
        cardStyleMedium: {
            width: 90 + '%',
            margin: '0% 5%',
            height: 100 + '%',
        },
        cardStyleMobile: {
            width: 100 + '%',
            margin: '24px 0%',
            height: 100 + '%',
        },
        blogCardRootStyle: {
            flex: 1,
            margin: '24px 24px 24px 60px',
        },
        blogCardRootStyleMobile: {
            flex: 1,
            margin: '24px 0px',
        },
        blogCardStyle: {
            marginTop: (toolbarSize + 8) + 'px',
        },
        projectsCardStyle: {
            width: 70 + '%',
            margin: '0% 15% 1% 15%',
        },
        projectsCardStyleMobile: {
            width: 100 + '%',
            margin: '24px 0%',
        },
        projectsCardStyleMedium: {
            width: 80 + '%',
            margin: '0% 15% 1% 15%',
        },
        cardImageStyle: {
        },
        cardImageStyleLandscape: {
            objectFit: 'cover',
            objectPosition: 'bottom',
            maxHeight: '60vh'
        },
        blogCardImageStyle: {
            objectFit: 'cover',
            objectPosition: 'bottom',
            height: 'calc(75vh)'
        },
        cardTextStyle: {
            fontSize: 20 + 'px',
            lineHeight: 26 + 'px',
        },
        cardTextStyleMobile: {
            fontSize: 32 + 'px',
        },
        cardTextStyleMobileLandscape: {
            fontSize: 32 + 'px',
            flex: 1,
            margin: 'auto',
        },
        cardTextStyleMedium: {
            fontSize: 16 + 'px',
            lineHeight: 20 + 'px',
        },
        projectsCardTextStyle: {
            fontSize: 14 + 'px',
            lineHeight: 18 + 'px',
        },
        toolbarStyle: {
            backgroundColor: 'rgb(244, 217, 71)',
            width: 100 + '%',
            position: 'fixed',
            top : appBarHeight + 'px',
            zIndex: 1000,
        },
        toolbarStyleMobile: {
            backgroundColor: 'rgb(244, 217, 71)',
            width: 100 + '%',
            position: 'fixed',
            top : 0 + 'px',
            padding : '0px 20px 0px 0px',
            height : toolbarSize + 'px',
            zIndex: 1000,
        },
        aboutUSHeaderStyle: {
            fontFamily: muiTheme.fontFamily,
            color: 'white',
            textAlign: 'center',
            width: 100 + '%',
            fontSize: 22 + 'px',
            lineHeight: 44 + 'px',
            margin: 0 + 'px',
        },
        aboutUSHeaderStyleMobile: {
            fontFamily: muiTheme.fontFamily,
            color: 'white',
            textAlign: 'center',
            width: 100 + '%',
            fontSize: 36 + 'px',
            lineHeight: 48 + 'px',
            margin: 0 + 'px',
        },
        aboutUSHeaderStyleSmall: {
            fontFamily: muiTheme.fontFamily,
            color: 'white',
            textAlign: 'center',
            width: 100 + '%',
            fontSize: 18 + 'px',
            lineHeight: 44 + 'px',
            margin: 0 + 'px',
        },
        aboutUSDividerStyle: {
            maxWidth: 4 + 'px',
            flex: 1,
            margin: '60px 0px',
            backgroundColor: 'white',
        },
        contentImageStyle: {
            width: isMobile ? 100 + '%' : 50 + '%',
            margin: isMobile ? '0% 0%' : '2% 25%',
        },
        contentRootStyle: {
            display: 'flex',
            flexDirection: 'column',
            width: 100 + '%',
        },
        contentStyle: {
            fontFamily: muiTheme.fontFamily,
        },
        projectDialogTitleStyle: {
            textAlign: 'center',
        },
        projectDialogTitleStyleMobile: {
            textAlign: 'center',
            fontSize: 48 + 'px',
            lineHeight: 52 + 'px',
        },
        fullScreenStyle: {
            width: 100 + '%',
            height: 'calc(100vh - ' + menuHeight + 'px - ' + appBarHeight + 'px)',
            marginTop: (toolbarSize) + 'px',
        },
        serviceFullScreenStyle: {
            width: 100 + '%',
            marginTop: (toolbarSize) + 'px',
        },
        projectFullScreenStyle: {
            width: 100 + '%',
            marginTop: (toolbarSize) + 'px',
        },
        projectFullScreenMinusTitleStyle: {
            display: 'flex',
            flexDirection: 'row',
        },
        projectFullScreenMinusTitleStyleMobile: {
            display: 'flex',
            flexDirection: 'column',
        },
        serviceFullScreenMinusTitleStyle: {
            display: 'flex',
            flexDirection: 'row',
        },
        serviceFullScreenMinusTitleStyleMobile: {
            display: 'flex',
            flexDirection: 'column',
        },
        equalFlex: {
            flex: 1,
        },
        homeButtonsInnerWrapper: {
            display: 'flex',
            flexDirection: 'column',
            flex: 10,
        },
        homeButtonRoot: {
            display: 'flex',
            flexDirection: 'row',
            height: 100 + '%',
        },
        sectionTitleRoot: {
            display: 'flex',
            flexDirection: 'row',
            height: (48 * 3) + 'px',
        },
        greySectionTitleRoot: {
            display: 'flex',
            flexDirection: 'row',
            height: (48 * 3) + 'px',
            backgroundColor: 'rgb(244, 244, 244)',
        },
        sectionTitleSpacing: {
            flex: 1,
            height: (48 * 3) + 'px',
        },
        sectionTitleMain: {
            flex: 10,
            height: (48 * 3) + 'px',
            fontFamily: muiTheme.fontFamily,
            fontSize: 48 + 'px',
            textAlign: 'center',
            lineHeight: (48 * 3) + 'px',
            color: 'rgb(244, 217, 71)',
            textDecoration: 'underline',
        },
        aboutUSSectionWrapper: {
            flex: 1,
            margin: 'auto',
        },
        aboutUSSectionWrapperMobile: {
            flex: 1,
            height: 240 + 'px',
            margin: 'auto',
        },
        aboutUSHeaderRoot: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            backgroundColor: 'rgb(244, 217, 71)',
        },
        aboutUSHeaderRootMobile: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            height: 240 + 'px',
            backgroundColor: 'rgb(244, 217, 71)',
        },
        aboutUSImageWrapper: {
            flex: 1,
            minHeight: '100%',
        },
        aboutUSImageWrapperMobile: {
            flex: 1,
            maxWidth: 100 + '%',
        },
        aboutUSImage: {
            backgroundImage: "url(" + about_us_image_url + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            minHeight: '100%',
        },
        aboutUSImageMobile: {
            width: 100 + '%',
        },
        aboutUSImageMobileLandscape: {
            width: 100 + '%',
            maxHeight: '60vh',
            objectFit: 'cover',
            objectPosition: 'bottom',
        },
        aboutUSContentText: {
            fontFamily: muiTheme.fontFamily,
            margin: '0% 5%',
            fontSize: 23 + 'px',
        },
        aboutUSContentTextMedium: {
            fontFamily: muiTheme.fontFamily,
            margin: '0% 5%',
            fontSize: 18 + 'px',
        },
        aboutUSContentTextMobile: {
            fontFamily: muiTheme.fontFamily,
            margin: '24px 5%',
            fontSize: 32 + 'px',
        },
        aboutSectionRoot: {
            display: 'flex',
            flexDirection: 'column',
            width: 100 + '%',
            marginTop: (toolbarSize) + 'px',
        },
        aboutUSContentRoot: {
            flex: 1,
            height: 100 + '%',
            backgroundColor: 'rgb(244, 244, 244)',
        },
        aboutUSTop: {
            display: 'flex',
            flexDirection: 'row',
            flex: 3,
            backgroundColor: 'rgb(244, 244, 244)',
        },
        multiCardWrapper: {
            display: 'flex',
            flexDirection: 'column',
            height: 100 + '%',
        },
        contactRoot: {
            display: 'flex',
            flexDirection: 'row',
            flex: 3,
            marginBottom: desktopControlHeight + 'px',
        },
        contactRootMobile: {
            display: 'flex',
            flexDirection: 'column',
            flex: 3,
            marginBottom: desktopControlHeight + 'px',
        },
        contactFormRoot: {
            flex: 1,
            height: 100 + '%',
            overflow: 'hidden',
            fontFamily: muiTheme.fontFamily,
            textAlign: 'center',
        },
        contactFormText: {
            padding: '0.5% 10%',
        },
        contactFormTextMobile: {
            padding: '0.5% 10%',
            fontSize: 28 + 'px',
        },
        contactInfoRoot: {
            flex: 1,
            height: 100 + '%',
            maxWidth: 50 + '%',
        },
        contactInfoRootMobile: {
            flex: 1,
            height: 100 + '%',
        },
        contactInfoImage: {
            marginTop: 50 + 'px',
        },
        contactInfoImageMobile: {
            width: 90 + '%',
            margin: '0% 5%',
        },
        contactInfoImageSmall: {
            marginTop: 50 + 'px',
            width: 100 + '%',
        },
        contactInfoTextHeader: {
            fontSize: 48 + 'px',
        },
        contactInfoTextWrapper: {
            display: 'flex',
            flexDirection: 'row',
        },
        contactInfoTextWrapperSmall: {
            display: 'flex',
            flexDirection: 'column',
        },
        contactInfoTextWrapperMobile: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 50 + 'px',
        },
        contactInfoTextContainer: {
            flex: 1,
            textAlign: 'center',
        },
        contactInfoText: {
            margin: '0.25em 0em',
        },
        contactInfoTextMobile: {
            margin: '0.25em 0em',
            fontSize: 32 + 'px',
        },
        contactBanner: {
            height: (48 * 3) + 'px',
            width: 100 + '%',
            backgroundColor: 'rgb(244, 217, 71)',
        },
        contactInfoWrapper: {
            fontFamily: muiTheme.fontFamily,
            textAlign: 'center',
        },
        w2pformRootStyle: {
            padding: '0.5% 10%',
            width: 'unset',
        },
        w2pformRecaptchaMobileStyle: {
            height: '100%',
        },
        contactSectionRoot: {
            display: 'flex',
            flexDirection: 'column',
            width: 100 + '%',
            marginTop: (toolbarSize) + 'px',
            backgroundColor: 'rgb(244, 244, 244)',
        },
        blogSectionRoot: {
            width: 100 + '%',
            marginTop: (toolbarSize) + 'px',
        },
        blogContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        blogCardWrapper: {
            display: 'flex',
            flexDirection: 'column',
            flex: 5,
        },
        blogListStyle: {
            position: 'sticky',
            top: (toolbarSize) + 'px',
        },
        blogMenuRoot: {
            flex: 1,
            backgroundColor: 'rgb(244, 244, 244)',
            margin: '148px 24px 24px',
        },
        blogMenuRootMobile: {
            flex: 1,
            margin: '24px',
        },
        mainMenuItemStyle: {
            fontSize: 38 + 'px',
            lineHeight: 64 + 'px',
        },
        mainMenuStyle: {
            width: 450 + 'px',
        },
        dialogStandardActionsButtonStyle: {
            height: 72 + 'px',
            lineHeight: 72 + 'px',
        },
        dialogStandardActionsTitleStyle: {
            fontSize: 48 + 'px',
            lineHeight: 72 + 'px',
        },
        mainMenuNarrowItemStyle: {
            margin: '10px 0px',
        },
    };
}


var constructionHomeStyles = createConstructionHomeStyles();


export default class ConstructHome extends React.PureComponent {

    constructor(props) {
        super(props);

        const devicePixelRatio = roundNumber(window.devicePixelRatio);
        const isPortrait = isPortraitOrientation()

        constructionHomeStyles = createConstructionHomeStyles(devicePixelRatio, isPortrait, props.staticUrl);

        this.onOrientationChange = this.onOrientationChange.bind(this);

        this.handleRequestClose = this.handleRequestClose.bind(this);

        this.state = {
            isPortrait: isPortrait,
            open: false,
            contentKey: undefined,
            mw_1080: window.matchMedia("(max-width: 1110px)").matches,
            sm: window.matchMedia("(max-width: 860px)").matches,
            uid: generateRandomNumber() + "",
        };
    }

    onOrientationChange() {
        this.setState({
            isPortrait: isPortraitOrientation(),
        })
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.isPortrait !== this.state.isPortrait) {
            constructionHomeStyles = createConstructionHomeStyles(roundNumber(window.devicePixelRatio), nextState.isPortrait, nextProps.staticUrl);
        }
    }

    componentDidMount() {
        window.addEventListener('orientationchange', this.onOrientationChange);
        window.matchMedia("(max-width: 1110px)").addListener((e) => {this.setState({mw_1080: e.matches, })});
        window.matchMedia("(max-width: 860px)").addListener((e) => {this.setState({sm: e.matches, })});
    }

    UNSAFE_componentWillMount() {
        injectStyle(constructionHomeStyles.dialogScrollCSS, this.state.uid);

        if (isMobile) {
            injectStyle(".projectDisplay h1 {font-size: 48px; text-align: center; }");
            injectStyle(".projectDisplay p {font-size: 32px; }");
        }
    }

    componentWillUnmount() {
        removeStyle(this.state.uid);
    }

    componentWillUnmount() {
        window.removeEventListener('orientationchange', this.onOrientationChange);
    }

    handleRequestClose(event) {
        event.stopPropagation();
        event.preventDefault();

        this.setState({open: false, });
    }

    render() {
        const {staticUrl, } = this.props;
        const {isPortrait, } = this.state;

        function generateContent(contentKey) {
            if (contentKey === 'DATA SCIENCE') {
                return (
                    <div style={constructionHomeStyles.contentRootStyle}>
                        <div className={"projectDisplay"} style={constructionHomeStyles.contentStyle}>
                            {isMobile && !isPortrait ? null : (<img
                                src={"/helmets.png"}
                                style={constructionHomeStyles.contentImageStyle}
                                />)}
                            <h1>Project:</h1>
                                <p>A Civil Contractor had a large labour force working on multiple Enterprise Bargaining
                                Agreements when a dispute was raised through the Union relating to labour rates.</p>
                            <h1>Rationale:</h1>
                                <p>Due to the restrictions of the payroll software used by the contractor, they were
                                calculating the rates manually which was extremely time consuming and prone to errors.</p>
                            <h1>Delivery:</h1>
                                <p>We developed an extraction tool to gather the full data set and completed the forensic
                                analysis which provided the organisation with a comprehensive report on the incorrect
                                payments as well as the ability to resolve the union dispute.</p>
                            <h1>Summary:</h1>
                                <p>While most organisations have existing systems to manage their data, when an issue
                                arises, they often cannot extract the data they need or have to revert to manual
                                processes to identify and fix the issue.  ConstructAgility builds micro-solutions to
                                keep business moving.</p>

                            <h1>Testimonial:</h1>
                                <p>We worked with ConstructAgility when we discovered an issue with our payments to
                                staff working on multiple sites under different EBA's.  We had a manual process to
                                rectify the issue but decided to engage ConstructAgility as it was taking too much
                                time and was a high risk to our business.  The solution was developed quickly and
                                delivered extensive analysis on our previous transactions as well as solving the issue
                                going forwards.  We will definitely work with ConstructAgility again to tackle other
                                issues in our business.</p>
                                <p>Owner, Civil Contractor, Qld.</p>
                        </div>
                    </div>
                );
            } else if (contentKey === 'ADVISORY') {
                return (
                    <div style={constructionHomeStyles.contentRootStyle}>
                        <div className={"projectDisplay"} style={constructionHomeStyles.contentStyle}>
                            {isMobile && !isPortrait ? null : (<img
                                src={"/land_access.png"}
                                style={constructionHomeStyles.contentImageStyle}
                                />)}
                            <h1>Project:</h1>
                                <p>Development of an integrated Land Access tool for a mining company to previous
                                unauthorised access of tenements without necessary permits.</p>
                            <h1>Rationale:</h1>
                                <p>The mining company was already gathering the data using IVMS with its primary
                                function to track vehicles, speed, usage etc so the data was available.
                                ConstructAgility developed a warning system to advise vehicle users when they were
                                approaching an area without the relevant permits.</p>
                            <h1>Delivery:</h1>
                                <p>A functioning prototype was developed but not implemented due to the downturn in the
                                mining sector.  It was fully integrated with the vehicle IVMS as well as the
                                organisations land access and permitting situation.</p>
                            <h1>Summary:</h1>
                                <p>This is a highly useful tool which can be expanded depending on the organisational
                                requirements and will reduce compensation and issues arising from unauthorised land
                                access.</p>

                            <h1>Testimonial:</h1>
                                <p>We are currently looking at this type of solution and ConstructAgility gave us some
                                fantastic advice about the approach we should consider and what pitfalls to watch for,
                                we are currently developing our scope and approvals but will be looking to work with
                                ConstructAgility once we move forward.</p>
                                <p>Systems Manager, LNG Operator, QLD.</p>
                        </div>
                    </div>
                );
            } else if (contentKey === 'DATA ANALYSIS') {
                return (
                    <div style={constructionHomeStyles.contentRootStyle}>
                        <div className={"projectDisplay"} style={constructionHomeStyles.contentStyle}>
                            {isMobile && !isPortrait ? null : (<img
                                src={"/data_science.png"}
                                style={constructionHomeStyles.contentImageStyle}
                                />)}
                            <h1>Project:</h1>
                                <p>To track capital expenditure in the development of 2000 gas wells in Central
                                Queensland.  This project had a 6 week time frame for completion.</p>
                            <h1>Rationale:</h1>
                                <p>Cost control is critical to the management of expenditure across the FSG group.
                                There was a backlog in processing invoices so there was no visibility across real-time
                                expenditure which was adversely affecting budgets, forecast and the claims process.</p>
                                <p>A cost control solution rectified this issue as well as delivering a real time
                                financial model, providing high level insights into wider challenges on the
                                project. </p>
                            <h1>Delivery:</h1>
                                <p>The solution was developed using a mixture of open-source software tools and big data
                                analytics to provide a system which was both user friendly and insightful from field
                                operations through to senior management.</p>
                                <p>By using the latest innovations in data analytics and rapid software development,
                                a cost effective solution was created.  This automated the invoicing processes including
                                accurately cost coding each line item and  completed all tasks with 95% accuracy and
                                potentially reducing the headcount required to complete the task by a
                                minimum of 80%.</p>
                            <h1>Summary:</h1>
                                <p>The project was completed in 6 weeks with a comprehensive solution in place.
                                The potential reduction in head-count is substantial but the key outcome is the
                                potential usage of the project model for combatting future challenges.</p>

                            <h1>Testimonial:</h1>
                                <p>We engaged ConstructAgility based on a referral due to a major invoicing and cost
                                management issue within one of our groups. We had a major backlog and 6 weeks to
                                rectify it.  The tool produced by ConstructAgility was incredible, not only did it
                                deliver what we wanted but was able to answer any other queries we had relating to
                                the invoices, something we could not do with our own ERP.
                                I would definitely recommend!</p>
                                <p>Commercial Manager, LNG Operator, QLD.</p>
                        </div>
                    </div>
                );
            } else if (contentKey === 'MICRO-SOLUTIONS') {
                return (
                    <div style={constructionHomeStyles.contentRootStyle}>
                        <div className={"projectDisplay"} style={constructionHomeStyles.contentStyle}>
                            {isMobile && !isPortrait ? null : (<img
                                src={'/migration.png'}
                                style={constructionHomeStyles.contentImageStyle}
                                />)}
                            <h1>Project:</h1>
                                <p>Extract manufacturing data from a 40 year old system (AlphaMicro), clean and organise
                                 into usable dataset to integrate with new ERP system.</p>
                            <h1>Rationale:</h1>
                                <p>The data contained in the AlphaMicro system was critical to the success of the new
                                ERP but the implementation team were not able to access the data. </p>
                            <h1>Delivery:</h1>
                                <p>A bespoke data extraction tool was developed to access the data and present it in a
                                usable format.  This tool allowed the organisation to analyse and integrate its ERP
                                system quickly and effectively.</p>
                            <h1>Summary:</h1>
                                <p>The project was completed in 4 weeks with all data successfully transitioned to the
                                new ERP.</p>

                            <h1>Testimonial:</h1>
                                <p>We engaged ConstructAgility as we were converting from an antiquated system to MyOB
                                and we couldn't get the data to transfer from one to the other despite engaging a number
                                of specialist consultants.  James is a wizard with data, I can't say I understand what
                                he does but it works!</p>
                                <p>Director, Manufacturing Business, Sydney.</p>
                        </div>
                    </div>
                );
            } else if (contentKey === 'EXTRACTING COMMERCIAL VALUE FROM DATA') {
                return (
                    <div style={constructionHomeStyles.contentRootStyle}>
                        <div className={"projectDisplay"} style={constructionHomeStyles.contentStyle}>
                            {isMobile && !isPortrait ? null : (<img
                                src={'/productivity.png'}
                                style={constructionHomeStyles.contentImageStyle}
                                />)}
                            <h1>Project:</h1>
                                <p>To manage the project controls for a $100m pipeline project for a major LNG project.
                                 Construct Agility set up an automated reporting system and the data collected was used
                                 across the project by the contractor.</p>
                            <h1>Rationale:</h1>
                                <p>Client requires daily, weekly and monthly reporting.  Collecting data in real time in
                                a construction environment is challenging and the data can be incomplete or
                                inaccurate. </p>
                                <p>Constructagility provided a reporting tool that would automatically generate reports,
                                to the specific project requirements and process the information 80% faster, mitigating
                                the challenges as outlined above.</p>
                            <h1>Delivery:</h1>
                                <p>First the contract was reviewed and the required data was identified for each
                                department; finance need the progress data for payment, construction require progress
                                visuals indicating where work is still to be completed and quality need to validate all
                                information.  A customised data capture and report generating tool was developed to
                                ensure successful delivery. </p>
                            <h1>Summary:</h1>
                                <p>With a unique blend of skills across the project arena, Construct Agility has the
                                ability to create unique, specific solutions on a project basis.  The time and cost
                                savings are significant as well as a stronger client relationship due to the reliability
                                and accuracy of your reporting.    The applications are endless. </p>

                            <h1>Testimonial:</h1>
                                <p>Construct Agility designed a fit for purpose reporting tool which ensured we met all
                                deadlines and had useful data to manage our progress, resulting in far less man hours
                                being invested by our engineers</p>
                                <p>Project Manager</p>
                        </div>
                    </div>
                );
            } else if (contentKey === "ENERGY") {
                return (
                    <div style={constructionHomeStyles.contentRootStyle}>
                        <div className={"projectDisplay"} style={constructionHomeStyles.contentStyle}>
                            {isMobile && !isPortrait ? null : (<img
                                src={'/pipeline.png'}
                                style={constructionHomeStyles.contentImageStyle}
                                />)}
                            <h1>Project:</h1>
                                <p>To extract all engineering, procurement and construction data from various sources
                                including GIS mapping, Primavera P6, MS Project and other corporate systems to develop
                                bills of quantities, budgets, resourcing plans and construction methodologies.</p>
                            <h1>Rationale:</h1>
                                <p>Our clients usual method was a manual process to extract the relevant information
                                and collate it, leave room for human error as well as the time factor to complete this
                                in a business project set up environmment.</p>
                            <h1>Delivery:</h1>
                                <p>Over the course of 12 weeks, Construct Agility built a number of tools to extract
                                the data required, analyse and present the information to support project decisions.
                                The information was used in contract negotiations, contracts administration, to solve
                                engineering and procurement queries as well as providing evidence ot support claims and
                                delays.</p>
                            <h1>Summary:</h1>
                                <p>Our client used the solution extensively throughout the projects to check and verify
                                their work and ensure that they were delivering to the client specification,
                                which ensured that errors and discrepancies were minimised.</p>

                            <h1>Testimonial:</h1>
                                <p>We referred to the documents produced by ConstructAgility as our bible in the office,
                                it was more accurate than any data provided by the client, identified errors and
                                duplications in the information and made any queries relating to materials or quantities
                                a breeze to deal with!  I used it every single day.</p>
                                <p>Senior Project Engineer, Pipeline Contractor.</p>
                        </div>
                    </div>
                );
            } else if (contentKey === "FINANCE") {
                return (
                    <div style={constructionHomeStyles.contentRootStyle}>
                        <div className={"projectDisplay"} style={constructionHomeStyles.contentStyle}>
                            {isMobile && !isPortrait ? null : (<img
                                src={'/data_science.png'}
                                style={constructionHomeStyles.contentImageStyle}
                                />)}
                            <h1>Project:</h1>
                                <p>To track capital expenditure in the development of 2000 gas wells in Central
                                Queensland.  This project had a 6 week time frame for completion.</p>
                            <h1>Rationale:</h1>
                                <p>Cost control is critical to the management of expenditure across the FSG group.
                                There was a backlog in processing invoices so there was no visibility across real-time
                                expenditure which was adversely affecting budgets, forecast and the claims process.</p>
                                <p>A cost control solution rectified this issue as well as delivering a real time
                                financial model, providing high level insights into wider challenges on the project.</p>
                            <h1>Delivery:</h1>
                                <p>The solution was developed using a mixture of open-source software tools and big data
                                analytics to provide a system which was both user friendly and insightful from field
                                operations through to senior management.</p>
                                <p>By using the latest innovations in data analytics and rapid software development, a
                                cost effective solution was created.  This automated the invoicing processes including
                                accurately cost coding each line item and  completed all tasks with 95% accuracy and '
                                potentially reducing the headcount required to complete the task by a minimum
                                of 80%.</p>
                            <h1>Summary:</h1>
                                <p>The project was completed in 6 weeks with a comprehensive solution in place.
                                The potential reduction in head-count is substantial but the key outcome is the
                                potential usage of the project model for combatting future challenges.</p>

                            <h1>Testimonial:</h1>
                                <p>We engaged ConstructAgility based on a referral due to a major invoicing and cost
                                management issue within one of our groups. We had a major backlog and 6 weeks to
                                rectify it.  The tool produced by ConstructAgility was incredible, not only did it
                                deliver what we wanted but was able to answer any other queries we had relating to the
                                invoices, something we could not do with our own ERP.
                                I would definitely recommend!</p>
                                <p>Commercial Manager, LNG Operator, QLD.</p>
                        </div>
                    </div>
                );
            } else if (contentKey === "PROJECTS") {
                return (
                    <div style={constructionHomeStyles.contentRootStyle}>
                        <div className={"projectDisplay"} style={constructionHomeStyles.contentStyle}>
                            {isMobile && !isPortrait ? null : (<img
                                src={'/productivity.png'}
                                style={constructionHomeStyles.contentImageStyle}
                                />)}
                            <h1>Project:</h1>
                                <p>To manage the project controls for a $100m pipeline project for a major LNG project.
                                Construct Agility set up an automated reporting system and the data collected was used
                                across the project by the contractor. </p>
                            <h1>Rationale:</h1>
                                <p>Client requires daily, weekly and monthly reporting.  Collecting data in real time in
                                a construction environment is challenging and the data can be incomplete or
                                inaccurate.</p>
                                <p>Constructagility provided a reporting tool that would automatically generate reports,
                                to the specific project requirements and process the information 80% faster, mitigating
                                the challenges as outlined above.</p>
                            <h1>Delivery:</h1>
                                <p>First the contract was reviewed and the required data was identified for each
                                department; finance need the progress data for payment, construction require progress
                                visuals indicating where work is still to be completed and quality need to validate all
                                information.  A customised data capture and report generating tool was developed to
                                ensure successful delivery.</p>
                            <h1>Summary:</h1>
                                <p>With a unique blend of skills across the project arena, Construct Agility has the
                                ability to create unique, specific solutions on a project basis.  The time and cost
                                savings are significant as well as a stronger client relationship due to the reliability
                                and accuracy of your reporting.    The applications are endless. </p>

                            <h1>Testimonial:</h1>
                                <p>Construct Agility designed a fit for purpose reporting tool which ensured we met all
                                deadlines and had useful data to manage our progress, resulting in far less man hours
                                being invested by our engineers</p>
                                <p>Project Manager</p>
                        </div>
                    </div>
                );
            } else if (contentKey === "BIG DATA") {
                return (
                    <div style={constructionHomeStyles.contentRootStyle}>
                        <div className={"projectDisplay"} style={constructionHomeStyles.contentStyle}>
                            {isMobile && !isPortrait ? null : (<img
                                src={'/migration.png'}
                                style={constructionHomeStyles.contentImageStyle}
                                />)}
                            <h1>Project:</h1>
                                <p>Extract manufacturing data from a 40 year old system (AlphaMicro), clean and organise
                                into usable dataset to integrate with new ERP system.</p>
                            <h1>Rationale:</h1>
                                <p>The data contained in the AlphaMicro system was critical to the success of the new
                                ERP but the implementation team were not able to access the data.</p>
                            <h1>Delivery:</h1>
                                <p>A bespoke data extraction tool was developed to access the data and present it in a
                                usable format.  This tool allowed the organisation to analyse and integrate its ERP
                                system quickly and effectively.</p>
                            <h1>Summary:</h1>
                                <p>The project was completed in 4 weeks with all data successfully transitioned to the
                                new ERP.</p>

                            <h1>Testimonial:</h1>
                                <p>We engaged ConstructAgility as we were converting from an antiquated system to MyOB
                                and we couldn't get the data to transfer from one to the other despite engaging a number
                                of specialist consultants.  James is a wizard with data, I can't say I understand what
                                he does but it works!</p>
                                <p>Director, Manufacturing Business, Sydney</p>
                        </div>
                    </div>
                );
            } else if (contentKey === "Project Delivery") {
                return (
                    <div style={constructionHomeStyles.contentRootStyle}>
                        <div className={"projectDisplay"} style={constructionHomeStyles.contentStyle}>
                            {isMobile && !isPortrait ? null : (<img
                                src={'/pipeline.png'}
                                style={constructionHomeStyles.contentImageStyle}
                                />)}
                            <h1>Project:</h1>
                                <p>To extract all engineering, procurement and construction data from various sources
                                including GIS mapping, Primavera P6, MS Project and other corporate systems to develop
                                bills of quantities, budgets, resourcing plans and construction methodologies.</p>
                            <h1>Rationale:</h1>
                                <p>Our clients usual method was a manual process to extract the relevant information
                                and collate it, leave room for human error as well as the time factor to complete this
                                in a business project set up environmment.</p>
                            <h1>Delivery:</h1>
                                <p>Over the course of 12 weeks, Construct Agility built a number of tools to extract
                                the data required, analyse and present the information to support project decisions.
                                The information was used in contract negotiations, contracts administration, to solve
                                engineering and procurement queries as well as providing evidence ot support claims and
                                delays.</p>
                            <h1>Summary:</h1>
                                <p>Our client used the solution extensively throughout the projects to check and verify
                                their work and ensure that they were delivering to the client specification,
                                which ensured that errors and discrepancies were minimised.</p>

                            <h1>Testimonial:</h1>
                                <p>We referred to the documents produced by ConstructAgility as our bible in the office,
                                it was more accurate than any data provided by the client, identified errors and
                                duplications in the information and made any queries relating to materials or quantities
                                a breeze to deal with!  I used it every single day.</p>
                                <p>Senior Project Engineer, Pipeline Contractor.</p>
                        </div>
                    </div>
                );
            } else if (contentKey === "Increasing Productivity") {
                return (
                    <div style={constructionHomeStyles.contentRootStyle}>
                        <div className={"projectDisplay"} style={constructionHomeStyles.contentStyle}>
                            {isMobile && !isPortrait ? null : (<img
                                src={'/productivity.png'}
                                style={constructionHomeStyles.contentImageStyle}
                                />)}
                            <h1>Project:</h1>
                                <p>To manage the project controls for a $100m pipeline project for a major LNG project.
                                 Construct Agility set up an automated reporting system and the data collected was used
                                 across the project by the contractor.</p>
                            <h1>Rationale:</h1>
                                <p>Client requires daily, weekly and monthly reporting.  Collecting data in real time in
                                a construction environment is challenging and the data can be incomplete or
                                inaccurate. </p>
                                <p>Constructagility provided a reporting tool that would automatically generate reports,
                                to the specific project requirements and process the information 80% faster, mitigating
                                the challenges as outlined above.</p>
                            <h1>Delivery:</h1>
                                <p>First the contract was reviewed and the required data was identified for each
                                department; finance need the progress data for payment, construction require progress
                                visuals indicating where work is still to be completed and quality need to validate all
                                information.  A customised data capture and report generating tool was developed to
                                ensure successful delivery. </p>
                            <h1>Summary:</h1>
                                <p>With a unique blend of skills across the project arena, Construct Agility has the
                                ability to create unique, specific solutions on a project basis.  The time and cost
                                savings are significant as well as a stronger client relationship due to the reliability
                                and accuracy of your reporting.    The applications are endless. </p>

                            <h1>Testimonial:</h1>
                                <p>Construct Agility designed a fit for purpose reporting tool which ensured we met all
                                deadlines and had useful data to manage our progress, resulting in far less man hours
                                being invested by our engineers</p>
                                <p>Project Manager</p>
                        </div>
                    </div>
                );
            } else if (contentKey === "Plant & Labour Analysis") {
                return (
                    <div style={constructionHomeStyles.contentRootStyle}>
                        <div className={"projectDisplay"} style={constructionHomeStyles.contentStyle}>
                            {isMobile && !isPortrait ? null : (<img
                                src={'/helmets.png'}
                                style={constructionHomeStyles.contentImageStyle}
                                />)}
                            <h1>Project:</h1>
                                <p>A Civil Contractor had a large labour force working on multiple Enterprise Bargaining
                                Agreements when a dispute was raised through the Union relating to labour rates.</p>
                            <h1>Rationale:</h1>
                                <p>Due to the restrictions of the payroll software used by the contractor, they were
                                calculating the rates manually which was extremely time consuming and prone to errors.</p>
                            <h1>Delivery:</h1>
                                <p>We developed an extraction tool to gather the full data set and completed the forensic
                                analysis which provided the organisation with a comprehensive report on the incorrect
                                payments as well as the ability to resolve the union dispute.</p>
                            <h1>Summary:</h1>
                                <p>While most organisations have existing systems to manage their data, when an issue
                                arises, they often cannot extract the data they need or have to revert to manual
                                processes to identify and fix the issue.  ConstructAgility builds micro-solutions to
                                keep business moving.</p>

                            <h1>Testimonial:</h1>
                                <p>We worked with ConstructAgility when we discovered an issue with our payments to
                                staff working on multiple sites under different EBA's.  We had a manual process to
                                rectify the issue but decided to engage ConstructAgility as it was taking too much
                                time and was a high risk to our business.  The solution was developed quickly and
                                delivered extensive analysis on our previous transactions as well as solving the issue
                                going forwards.  We will definitely work with ConstructAgility again to tackle other
                                issues in our business.</p>
                                <p>Owner, Civil Contractor, Qld.</p>
                        </div>
                    </div>
                );
            } else if (contentKey === "Data Migration - AlphaMicro") {
                return (
                    <div style={constructionHomeStyles.contentRootStyle}>
                        <div className={"projectDisplay"} style={constructionHomeStyles.contentStyle}>
                            {isMobile && !isPortrait ? null : (<img
                                src={'/migration.png'}
                                style={constructionHomeStyles.contentImageStyle}
                                />)}
                            <h1>Project:</h1>
                                <p>Extract manufacturing data from a 40 year old system (AlphaMicro), clean and organise
                                 into usable dataset to integrate with new ERP system.</p>
                            <h1>Rationale:</h1>
                                <p>The data contained in the AlphaMicro system was critical to the success of the new
                                ERP but the implementation team were not able to access the data. </p>
                            <h1>Delivery:</h1>
                                <p>A bespoke data extraction tool was developed to access the data and present it in a
                                usable format.  This tool allowed the organisation to analyse and integrate its ERP
                                system quickly and effectively.</p>
                            <h1>Summary:</h1>
                                <p>The project was completed in 4 weeks with all data successfully transitioned to the
                                new ERP.</p>

                            <h1>Testimonial:</h1>
                                <p>We engaged ConstructAgility as we were converting from an antiquated system to MyOB
                                and we couldn't get the data to transfer from one to the other despite engaging a number
                                of specialist consultants.  James is a wizard with data, I can't say I understand what
                                he does but it works!</p>
                                <p>Director, Manufacturing Business, Sydney.</p>
                        </div>
                    </div>
                );
            } else if (contentKey === "Land Access Application") {
                return (
                    <div style={constructionHomeStyles.contentRootStyle}>
                        <div className={"projectDisplay"} style={constructionHomeStyles.contentStyle}>
                            {isMobile && !isPortrait ? null : (<img
                                src={'/land_access.png'}
                                style={constructionHomeStyles.contentImageStyle}
                                />)}
                            <h1>Project:</h1>
                                <p>Development of an integrated Land Access tool for a mining company to previous
                                unauthorised access of tenements without necessary permits.</p>
                            <h1>Rationale:</h1>
                                <p>The mining company was already gathering the data using IVMS with its primary
                                function to track vehicles, speed, usage etc so the data was available.
                                ConstructAgility developed a warning system to advise vehicle users when they were
                                approaching an area without the relevant permits.</p>
                            <h1>Delivery:</h1>
                                <p>A functioning prototype was developed but not implemented due to the downturn in the
                                mining sector.  It was fully integrated with the vehicle IVMS as well as the
                                organisations land access and permitting situation.</p>
                            <h1>Summary:</h1>
                                <p>This is a highly useful tool which can be expanded depending on the organisational
                                requirements and will reduce compensation and issues arising from unauthorised land
                                access.</p>

                            <h1>Testimonial:</h1>
                                <p>We are currently looking at this type of solution and ConstructAgility gave us some
                                fantastic advice about the approach we should consider and what pitfalls to watch for,
                                we are currently developing our scope and approvals but will be looking to work with
                                ConstructAgility once we move forward.</p>
                                <p>Systems Manager, LNG Operator, QLD.</p>
                        </div>
                    </div>
                );
            } else if (contentKey === "Gas Pipeline Construction") {
                return (
                    <div style={constructionHomeStyles.contentRootStyle}>
                        <div className={"projectDisplay"} style={constructionHomeStyles.contentStyle}>
                            {isMobile && !isPortrait ? null : (<img
                                src={'/pipeline.png'}
                                style={constructionHomeStyles.contentImageStyle}
                                />)}
                            <h1>Project:</h1>
                                <p>To manage the project controls for a $100m pipeline project for a major LNG project.
                                 Construct Agility set up an automated reporting system and the data collected was used
                                 across the project by the contractor.</p>
                            <h1>Rationale:</h1>
                                <p>Client requires daily, weekly and monthly reporting.  Collecting data in real time in
                                a construction environment is challenging and the data can be incomplete or
                                inaccurate. </p>
                                <p>Constructagility provided a reporting tool that would automatically generate reports,
                                to the specific project requirements and process the information 80% faster, mitigating
                                the challenges as outlined above.</p>
                            <h1>Delivery:</h1>
                                <p>First the contract was reviewed and the required data was identified for each
                                department; finance need the progress data for payment, construction require progress
                                visuals indicating where work is still to be completed and quality need to validate all
                                information.  A customised data capture and report generating tool was developed to
                                ensure successful delivery. </p>
                            <h1>Summary:</h1>
                                <p>With a unique blend of skills across the project arena, Construct Agility has the
                                ability to create unique, specific solutions on a project basis.  The time and cost
                                savings are significant as well as a stronger client relationship due to the reliability
                                and accuracy of your reporting.    The applications are endless. </p>

                            <h1>Testimonial:</h1>
                                <p>Construct Agility designed a fit for purpose reporting tool which ensured we met all
                                deadlines and had useful data to manage our progress, resulting in far less man hours
                                being invested by our engineers</p>
                                <p>Project Manager</p>
                        </div>
                    </div>
                );
            }
        }

        function generateDescriptionTitle(contentKey) {
            if (contentKey === 'DATA SCIENCE') {
                return "FORENSIC ANALYSIS - LABOUR PAYMENTS";
            } else if (contentKey === 'ADVISORY') {
                return "LAND ACCESS - APPLICATION";
            } else if (contentKey === 'DATA ANALYSIS') {
                return "AUTOMATED COST CONTROL";
            } else if (contentKey === 'MICRO-SOLUTIONS') {
                return "DATA MIGRATION - ALPHAMICRO";
            } else if (contentKey === 'EXTRACTING COMMERCIAL VALUE FROM DATA') {
                return "CONSTRUCTION REPORTING & ANALYTICS";
            }  else if (contentKey === 'Gas Pipeline Construction') {
                return "CONSTRUCTION REPORTING & ANALYTICS";
            } else if (contentKey === "ENERGY") {
                return "AUTOMATED PROJECT DELIVERY";
            } else if (contentKey === "FINANCE") {
                return "AUTOMATED COST CONTROL";
            } else if (contentKey === "PROJECTS") {
                return "CONSTRUCTION REPORTING & ANALYTICS";
            } else if (contentKey === "BIG DATA") {
                return "DATA MIGRATION - ALPHAMICRO";
            } else if (contentKey === "Project Delivery") {
                return "AUTOMATED PROJECT DELIVERY";
            } else if (contentKey === "Increasing Productivity") {
                return "AUTOMATED COST CONTROL";
            } else if (contentKey === 'Data Migration - AlphaMicro') {
                return "DATA MIGRATION - ALPHAMICRO";
            } else if (contentKey === 'Plant & Labour Analysis') {
                return "FORENSIC ANALYSIS - LABOUR PAYMENTS";
            } else if (contentKey === 'Land Access Application') {
                return "LAND ACCESS - APPLICATION";
            }
        }

        // create component for dialog actions, standard flat button
        const dialogStandardActions = [
            <FlatButton
                label={"Cancel"}
                style={isMobile ? constructionHomeStyles.dialogStandardActionsButtonStyle : undefined}
                labelStyle={isMobile ? constructionHomeStyles.dialogStandardActionsTitleStyle : undefined}
                secondary={true}
                onClick={this.handleRequestClose}
            />,];

        const projectsDialog = this.state.open ? (
            <Dialog
                open={this.state.open}
                title={generateDescriptionTitle(this.state.contentKey)}
                titleStyle={isMobile ? constructionHomeStyles.projectDialogTitleStyleMobile : constructionHomeStyles.projectDialogTitleStyle}
                actions={dialogStandardActions}
                autoScrollBodyContent={true}
                modal={true}
                bodyClassName={constructionHomeStyles.bodyClassName}
                children={generateContent(this.state.contentKey)}
                />
        ) : null;

        const mainMenu = isMobile || this.state.sm ? (
            <ToolbarGroup>
                <IconMenu
                    iconButtonElement={<IconButton style={isMobile ? constructionHomeStyles.iconButtonStyle : {}} iconStyle={isMobile ? constructionHomeStyles.iconButtonIconStyle : {}}><MoreVertIcon /></IconButton>}
                    anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                    targetOrigin={{horizontal: 'right', vertical: 'top'}}
                    listStyle={isMobile ? constructionHomeStyles.mainMenuStyle : {}}
                    useLayerForClickAway={true}
                    children={[
                        <ListItem key={0} primaryText={"Home"} href={"#home"} style={isMobile ? constructionHomeStyles.mainMenuItemStyle : {}} />,
                        <ListItem key={1} primaryText={"Services"} href={"#services"} style={isMobile ? constructionHomeStyles.mainMenuItemStyle : {}} />,
                        <ListItem key={2} primaryText={"About"} href={"#about_us"} style={isMobile ? constructionHomeStyles.mainMenuItemStyle : {}} />,
                        <ListItem key={3} primaryText={"Projects"} href={"#projects"} style={isMobile ? constructionHomeStyles.mainMenuItemStyle : {}} />,
                        <ListItem key={4} primaryText={"Contact"} href={"#contact"} style={isMobile ? constructionHomeStyles.mainMenuItemStyle : {}} />,
                        <Divider key={5} inset={false} />,
                        <ListItem key={6} primaryText={"Blog"} href={"#blog"} style={isMobile ? constructionHomeStyles.mainMenuItemStyle : {}} />,
                        <Divider key={7} inset={false} />,
                        <ListItem key={8} primaryText={"Meet the Founder"} href={"#blog_1"} style={isMobile ? constructionHomeStyles.mainMenuItemStyle : {}} />,
                        <ListItem key={9} primaryText={"Big Data & Heavy Vehicles"} href={"#blog_2"} style={isMobile ? constructionHomeStyles.mainMenuItemStyle : {}} />,
                        <ListItem key={10} primaryText={"Excel Rant!"} href={"#blog_3"} style={isMobile ? constructionHomeStyles.mainMenuItemStyle : {}} />,
                        <ListItem key={11} primaryText={"More Tech, More Problems"} href={"#blog_4"} style={isMobile ? constructionHomeStyles.mainMenuItemStyle : {}} />,
                        <ListItem key={12} primaryText={"What is Big Data?"} href={"#blog_5"} style={isMobile ? constructionHomeStyles.mainMenuItemStyle : {}} />,
                    ]}
                />
            </ToolbarGroup>
        ) : (
            <ToolbarGroup>
                <FlatButton key={0} label={"Home"} href={"#home"} style={this.state.mw_1080 ? constructionHomeStyles.mainMenuNarrowItemStyle : undefined} />
                <FlatButton key={1} label={"Services"} href={"#services"} style={this.state.mw_1080 ? constructionHomeStyles.mainMenuNarrowItemStyle : undefined} />
                <FlatButton key={2} label={"About"} href={"#about_us"} style={this.state.mw_1080 ? constructionHomeStyles.mainMenuNarrowItemStyle : undefined} />
                <FlatButton key={3} label={"Projects"} href={"#projects"} style={this.state.mw_1080 ? constructionHomeStyles.mainMenuNarrowItemStyle : undefined} />
                <FlatButton key={4} label={"Contact"} href={"#contact"} style={this.state.mw_1080 ? constructionHomeStyles.mainMenuNarrowItemStyle : undefined} />
                <FlatButton key={5} label={"Blog"} href={"#blog"} style={this.state.mw_1080 ? constructionHomeStyles.mainMenuNarrowItemStyle : undefined} />
            </ToolbarGroup>
        );

        // IMP NOTE - appBarComponentWrapperStyle is needed as dialog opening from button click offsets top: 0px
        const toolbar = isMobile ? (
            <Toolbar style={constructionHomeStyles.toolbarStyleMobile}>
                <ToolbarGroup
                    style={constructionHomeStyles.appBarComponentToolbarStyle}
                    firstChild={true}
                    >
                    <div style={constructionHomeStyles.appBarComponentWrapperStyle}>
                        {this.props.appBarComponent}
                    </div>
                </ToolbarGroup>
                <ToolbarGroup>
                    <img
                        src={'/logo_with_title.png'}
                        style={constructionHomeStyles.imgStyleLogoMobile}
                        />
                </ToolbarGroup>
                {mainMenu}
            </Toolbar>
        ) : (
            <Toolbar style={constructionHomeStyles.toolbarStyle}>
                <ToolbarGroup
                    firstChild={true}
                    >
                    <img
                        src={'/logo_with_title.png'}
                        style={constructionHomeStyles.imgStyleLogo}
                        />
                </ToolbarGroup>
                {mainMenu}
            </Toolbar>
        );

        const homeSection = (
            <div style={constructionHomeStyles.fullScreenStyle}>
                <img
                    src={'/sunset.jpg'}
                    style={isMobile ? constructionHomeStyles.imgStyleSunsetMobile : constructionHomeStyles.imgStyleSunset}
                    />

                <div style={constructionHomeStyles.homeButtonRoot}>
                    <div style={constructionHomeStyles.equalFlex} />
                    <div style={constructionHomeStyles.homeButtonsInnerWrapper}>
                        <FlatButton
                            backgroundColor={"transparent"}
                            onClick={() => {this.setState({open: true, contentKey: "DATA SCIENCE", })}}
                            label={"DATA SCIENCE"}
                            style={constructionHomeStyles.buttonStyle}
                            labelStyle={constructionHomeStyles.buttonLabelStyle}
                            />
                        <FlatButton
                            backgroundColor={"transparent"}
                            onClick={() => {this.setState({open: true, contentKey: "ADVISORY", })}}
                            label={"ADVISORY"}
                            style={constructionHomeStyles.buttonStyle}
                            labelStyle={constructionHomeStyles.buttonLabelStyle}
                            />
                        <FlatButton
                            backgroundColor={"transparent"}
                            onClick={() => {this.setState({open: true, contentKey: "DATA ANALYSIS", })}}
                            label={"DATA ANALYSIS"}
                            style={constructionHomeStyles.buttonStyle}
                            labelStyle={constructionHomeStyles.buttonLabelStyle}
                            />
                        <FlatButton
                            backgroundColor={"transparent"}
                            onClick={() => {this.setState({open: true, contentKey: "MICRO-SOLUTIONS", })}}
                            label={"MICRO-SOLUTIONS"}
                            style={constructionHomeStyles.buttonStyle}
                            labelStyle={constructionHomeStyles.buttonLabelStyle}
                            />
                        <FlatButton
                            backgroundColor={"transparent"}
                            onClick={() => {this.setState({open: true, contentKey: "EXTRACTING COMMERCIAL VALUE FROM DATA", })}}
                            label={"EXTRACTING COMMERCIAL VALUE FROM DATA"}
                            style={constructionHomeStyles.buttonStyle}
                            labelStyle={constructionHomeStyles.buttonLabelStyle}
                            />
                        {isIOS ? (<a name="services"></a>) : null}
                    </div>
                    <div style={constructionHomeStyles.equalFlex} />

                </div>
            </div>
        );

        const serviceSection = (
            <div style={constructionHomeStyles.serviceFullScreenStyle}>

                <div style={constructionHomeStyles.sectionTitleRoot}>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                    <div style={constructionHomeStyles.sectionTitleMain}>{"SERVICES"}</div>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                </div>

                <div style={isMobile ? constructionHomeStyles.serviceFullScreenMinusTitleStyleMobile : constructionHomeStyles.serviceFullScreenMinusTitleStyle}>
                    <div style={constructionHomeStyles.cardRootStyle}>
                        <Card
                            style={isMobile ? constructionHomeStyles.cardStyleMobile : this.state.mw_1080 ? constructionHomeStyles.cardStyleMedium : constructionHomeStyles.cardStyle}
                            containerStyle={isMobile && !isPortrait ? {display: 'flex', flexDirection: 'row', paddingBottom: 0 + 'px', } : undefined}
                            >
                            <CardMedia
                                style={isMobile ? isPortrait ? undefined : {flex: 1, } : undefined}
                                overlay={<CardTitle title={"DATA SCIENCE"} titleStyle={isMobile ? constructionHomeStyles.cardTitleStyleMobile : this.state.mw_1080 ? constructionHomeStyles.cardTitleStyleMedium : constructionHomeStyles.cardTitleStyle} />}
                                >
                                <img src={'/data_science.png'} style={constructionHomeStyles.cardImageStyle} />
                            </CardMedia>
                            <CardText style={isMobile ? isPortrait ? constructionHomeStyles.cardTextStyleMobile : constructionHomeStyles.cardTextStyleMobileLandscape : this.state.mw_1080 ? constructionHomeStyles.cardTextStyleMedium : constructionHomeStyles.cardTextStyle}>
                                We deal in data. We identify opportunities for our clients to use data to
                                their advantage.<br /><br />
                                We work with businesses to extract commercial value through the introduction
                                of micro-solutions which fit your existing systems.<br /><br />
                                Data science is a combination of statistical analysis and software development
                                and is the root of business transformation.<br /><br />
                            </CardText>
                        </Card>
                    </div>
                    <div style={constructionHomeStyles.cardRootStyle}>
                        <Card
                            style={isMobile ? constructionHomeStyles.cardStyleMobile : this.state.mw_1080 ? constructionHomeStyles.cardStyleMedium : constructionHomeStyles.cardStyle}
                            containerStyle={isMobile && !isPortrait ? {display: 'flex', flexDirection: 'row', paddingBottom: 0 + 'px', } : undefined}
                            >
                            <CardMedia
                                style={isMobile ? isPortrait ? undefined : {flex: 1, } : undefined}
                                overlay={<CardTitle title={"CONSTRUCTION TOOLS"} titleStyle={isMobile ? constructionHomeStyles.cardTitleStyleMobile : this.state.mw_1080 ? constructionHomeStyles.cardTitleStyleMedium : constructionHomeStyles.cardTitleStyle} />}
                                >
                                <img src={'/construction_tools.png'} style={constructionHomeStyles.cardImageStyle} />
                            </CardMedia>
                            <CardText style={isMobile ? isPortrait ? constructionHomeStyles.cardTextStyleMobile : constructionHomeStyles.cardTextStyleMobileLandscape : this.state.mw_1080 ? constructionHomeStyles.cardTextStyleMedium : constructionHomeStyles.cardTextStyle}>
                                We develop agile tools to connect existing softwares used throughout a project
                                lifecycle, removing manual processes and achieve major efficiency.<br /><br />
                                We partner with the construction industry to design & deliver innovation
                                solutions for bids and tenders as well as project delivery.<br /><br />
                                Dynamic scheduling, reporting, cost.<br /><br />
                            </CardText>
                        </Card>
                    </div>
                    <div style={constructionHomeStyles.cardRootStyle}>
                        <Card
                            style={isMobile ? constructionHomeStyles.cardStyleMobile : this.state.mw_1080 ? constructionHomeStyles.cardStyleMedium : constructionHomeStyles.cardStyle}
                            containerStyle={isMobile && !isPortrait ? {display: 'flex', flexDirection: 'row', paddingBottom: 0 + 'px', } : undefined}
                            >
                            <CardMedia
                                style={isMobile ? isPortrait ? undefined : {flex: 1, } : undefined}
                                overlay={<CardTitle title={"ORGANISATIONAL APPS"} titleStyle={isMobile ? constructionHomeStyles.cardTitleStyleMobile : this.state.mw_1080 ? constructionHomeStyles.cardTitleStyleMedium : constructionHomeStyles.cardTitleStyle} />}
                                >
                                <img src={'/org_apps.png'} style={constructionHomeStyles.cardImageStyle} />
                            </CardMedia>
                            <CardText style={isMobile ? isPortrait ? constructionHomeStyles.cardTextStyleMobile : constructionHomeStyles.cardTextStyleMobileLandscape : this.state.mw_1080 ? constructionHomeStyles.cardTextStyleMedium : constructionHomeStyles.cardTextStyle}>
                                Businesses have vast amounts of data and internal systems are  changing rapidly.
                                We provide bespoke data migration, management and analytical tools to give
                                organisations visabilty, data-driven decision making capability without a major
                                systems roll out.<br /><br />
                                Predictive analytics & machine learning driving business outcomes.<br /><br />
                            </CardText>
                        </Card>
                    </div>
                    {isIOS ? (<a name="about_us"></a>) : null}
                </div>
            </div>
        );

        const aboutSection = isMobile ? (
            <div style={constructionHomeStyles.aboutSectionRoot}>

                <div style={constructionHomeStyles.sectionTitleRoot}>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                    <div style={constructionHomeStyles.sectionTitleMain}>{"ABOUT US"}</div>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                </div>

                <div style={constructionHomeStyles.aboutUSImageWrapperMobile}>
                    <img
                        src={'/about_us.png'}
                        style={isPortrait ? constructionHomeStyles.aboutUSImageMobile : constructionHomeStyles.aboutUSImageMobileLandscape}
                        />
                </div>

                <div style={constructionHomeStyles.aboutUSTop}>
                    <div style={constructionHomeStyles.aboutUSContentRoot}>

                        <div style={constructionHomeStyles.aboutUSContentTextMobile}>
                            ConstructAgility was founded by James O' Driscoll in 2014, combining his passion
                            for innovation with his aptitude for data science.<br /><br />

                            ConstructAgility is a data science consultancy which builds bespoke solutions to ensure
                            accurate results every time.  We describe these as "micro-solutions".<br /><br />

                            We work on data migration, analysis and machine learning projects to provide our clients
                             with innovative solutions to drive better business outcomes.<br /><br />

                            With experience in Construction, Transport, Oil & Gas, Wind Energy and Manufacturing;
                            we are partnering with organisations who want to bring genuine innovation to their
                            business to gain a competitive edge.<br /><br />
                        </div>
                    </div>

                </div>

                <div style={constructionHomeStyles.aboutUSHeaderRootMobile}>
                    <div style={constructionHomeStyles.aboutUSSectionWrapperMobile}>
                        <FlatButton backgroundColor={"transparent"} label={"ENERGY"} style={constructionHomeStyles.headerButtonStyleMobile} labelStyle={constructionHomeStyles.buttonLabelStyleMobile} onClick={()=>{this.setState({open: true, contentKey: "ENERGY", })}}/>
                        <p style={constructionHomeStyles.aboutUSHeaderStyleMobile}>{"Efficiency"}</p>
                        <p style={constructionHomeStyles.aboutUSHeaderStyleMobile}>{"Automation"}</p>
                        <p style={constructionHomeStyles.aboutUSHeaderStyleMobile}>{"Predictability"}</p>
                    </div>
                    <div style={constructionHomeStyles.aboutUSDividerStyle} />
                    <div style={constructionHomeStyles.aboutUSSectionWrapperMobile}>
                        <FlatButton backgroundColor={"transparent"} label={"FINANCE"} style={constructionHomeStyles.headerButtonStyleMobile} labelStyle={constructionHomeStyles.buttonLabelStyleMobile} onClick={()=>{this.setState({open: true, contentKey: "FINANCE", })}} />
                        <p style={constructionHomeStyles.aboutUSHeaderStyleMobile}>{"Data Migration"}</p>
                        <p style={constructionHomeStyles.aboutUSHeaderStyleMobile}>{"Process Analytics"}</p>
                        <p style={constructionHomeStyles.aboutUSHeaderStyleMobile}>{"Modelling"}</p>
                    </div>
                </div>

                <div style={constructionHomeStyles.aboutUSHeaderRootMobile}>
                    <div style={constructionHomeStyles.aboutUSSectionWrapperMobile}>
                        <FlatButton backgroundColor={"transparent"} label={"PROJECTS"} style={constructionHomeStyles.headerButtonStyleMobile} labelStyle={constructionHomeStyles.buttonLabelStyleMobile} onClick={()=>{this.setState({open: true, contentKey: "PROJECTS", })}} />
                        <p style={constructionHomeStyles.aboutUSHeaderStyleMobile}>{"Innovation"}</p>
                        <p style={constructionHomeStyles.aboutUSHeaderStyleMobile}>{"Scheduling"}</p>
                        <p style={constructionHomeStyles.aboutUSHeaderStyleMobile}>{"Forensic Analysis"}</p>
                    </div>
                    <div style={constructionHomeStyles.aboutUSDividerStyle} />
                    <div style={constructionHomeStyles.aboutUSSectionWrapperMobile}>
                        <FlatButton backgroundColor={"transparent"} label={"BIG DATA"} style={constructionHomeStyles.headerButtonStyleMobile} labelStyle={constructionHomeStyles.buttonLabelStyleMobile} onClick={()=>{this.setState({open: true, contentKey: "BIG DATA", })}} />
                        <p style={constructionHomeStyles.aboutUSHeaderStyleMobile}>{"Analytics"}</p>
                        <p style={constructionHomeStyles.aboutUSHeaderStyleMobile}>{"Applications"}</p>
                        <p style={constructionHomeStyles.aboutUSHeaderStyleMobile}>{"Cloud"}</p>
                    </div>
                </div>

                {isIOS ? (<a name="projects"></a>) : null}
            </div>
        ) : (
            <div style={constructionHomeStyles.aboutSectionRoot}>
                <div style={constructionHomeStyles.aboutUSTop}>
                    <div style={constructionHomeStyles.aboutUSContentRoot}>

                        <div style={constructionHomeStyles.sectionTitleRoot}>
                            <div style={constructionHomeStyles.sectionTitleSpacing} />
                            <div style={constructionHomeStyles.sectionTitleMain}>{"ABOUT US"}</div>
                            <div style={constructionHomeStyles.sectionTitleSpacing} />
                        </div>

                        <div style={this.state.mw_1080 ? constructionHomeStyles.aboutUSContentTextMedium : constructionHomeStyles.aboutUSContentText}>
                            ConstructAgility was founded by James O' Driscoll in 2014, combining his passion
                            for innovation with his aptitude for data science.<br /><br />

                            ConstructAgility is a data science consultancy which builds bespoke solutions to ensure
                            accurate results every time.  We describe these as "micro-solutions".<br /><br />

                            We work on data migration, analysis and machine learning projects to provide our clients
                             with innovative solutions to drive better business outcomes.<br /><br />

                            With experience in Construction, Transport, Oil & Gas, Wind Energy and Manufacturing;
                            we are partnering with organisations who want to bring genuine innovation to their
                            business to gain a competitive edge.<br /><br />
                        </div>
                    </div>

                    <div style={constructionHomeStyles.aboutUSImageWrapper}>
                        <div style={constructionHomeStyles.aboutUSImage} />
                    </div>

                </div>

                <div style={constructionHomeStyles.aboutUSHeaderRoot}>
                    <div style={constructionHomeStyles.aboutUSSectionWrapper}>
                        <FlatButton backgroundColor={"transparent"} label={"ENERGY"} style={constructionHomeStyles.headerButtonStyle} labelStyle={this.state.mw_1080 ? this.state.sm ? constructionHomeStyles.buttonLabelStyleSmall : constructionHomeStyles.buttonLabelStyleMedium : constructionHomeStyles.buttonLabelStyle} onClick={()=>{this.setState({open: true, contentKey: "ENERGY", })}}/>
                        <p style={this.state.sm ? constructionHomeStyles.aboutUSHeaderStyleSmall : constructionHomeStyles.aboutUSHeaderStyle}>{"Efficiency"}</p>
                        <p style={this.state.sm ? constructionHomeStyles.aboutUSHeaderStyleSmall : constructionHomeStyles.aboutUSHeaderStyle}>{"Automation"}</p>
                        <p style={this.state.sm ? constructionHomeStyles.aboutUSHeaderStyleSmall : constructionHomeStyles.aboutUSHeaderStyle}>{"Predictability"}</p>
                    </div>
                    <div style={constructionHomeStyles.aboutUSDividerStyle} />
                    <div style={constructionHomeStyles.aboutUSSectionWrapper}>
                        <FlatButton backgroundColor={"transparent"} label={"FINANCE"} style={constructionHomeStyles.headerButtonStyle} labelStyle={this.state.mw_1080 ? this.state.sm ? constructionHomeStyles.buttonLabelStyleSmall : constructionHomeStyles.buttonLabelStyleMedium : constructionHomeStyles.buttonLabelStyle} onClick={()=>{this.setState({open: true, contentKey: "FINANCE", })}} />
                        <p style={this.state.sm ? constructionHomeStyles.aboutUSHeaderStyleSmall : constructionHomeStyles.aboutUSHeaderStyle}>{"Data Migration"}</p>
                        <p style={this.state.sm ? constructionHomeStyles.aboutUSHeaderStyleSmall : constructionHomeStyles.aboutUSHeaderStyle}>{"Process Analytics"}</p>
                        <p style={this.state.sm ? constructionHomeStyles.aboutUSHeaderStyleSmall : constructionHomeStyles.aboutUSHeaderStyle}>{"Modelling"}</p>
                    </div>
                    <div style={constructionHomeStyles.aboutUSDividerStyle} />
                    <div style={constructionHomeStyles.aboutUSSectionWrapper}>
                        <FlatButton backgroundColor={"transparent"} label={"PROJECTS"} style={constructionHomeStyles.headerButtonStyle} labelStyle={this.state.mw_1080 ? this.state.sm ? constructionHomeStyles.buttonLabelStyleSmall : constructionHomeStyles.buttonLabelStyleMedium : constructionHomeStyles.buttonLabelStyle} onClick={()=>{this.setState({open: true, contentKey: "PROJECTS", })}} />
                        <p style={this.state.sm ? constructionHomeStyles.aboutUSHeaderStyleSmall : constructionHomeStyles.aboutUSHeaderStyle}>{"Innovation"}</p>
                        <p style={this.state.sm ? constructionHomeStyles.aboutUSHeaderStyleSmall : constructionHomeStyles.aboutUSHeaderStyle}>{"Scheduling"}</p>
                        <p style={this.state.sm ? constructionHomeStyles.aboutUSHeaderStyleSmall : constructionHomeStyles.aboutUSHeaderStyle}>{"Forensic Analysis"}</p>
                    </div>
                    <div style={constructionHomeStyles.aboutUSDividerStyle} />
                    <div style={constructionHomeStyles.aboutUSSectionWrapper}>
                        <FlatButton backgroundColor={"transparent"} label={"BIG DATA"} style={constructionHomeStyles.headerButtonStyle} labelStyle={this.state.mw_1080 ? this.state.sm ? constructionHomeStyles.buttonLabelStyleSmall : constructionHomeStyles.buttonLabelStyleMedium : constructionHomeStyles.buttonLabelStyle} onClick={()=>{this.setState({open: true, contentKey: "BIG DATA", })}} />
                        <p style={this.state.sm ? constructionHomeStyles.aboutUSHeaderStyleSmall : constructionHomeStyles.aboutUSHeaderStyle}>{"Analytics"}</p>
                        <p style={this.state.sm ? constructionHomeStyles.aboutUSHeaderStyleSmall : constructionHomeStyles.aboutUSHeaderStyle}>{"Applications"}</p>
                        <p style={this.state.sm ? constructionHomeStyles.aboutUSHeaderStyleSmall : constructionHomeStyles.aboutUSHeaderStyle}>{"Cloud"}</p>
                    </div>
                </div>
            </div>
        );

        const projectSection = isMobile ? (
            <div style={constructionHomeStyles.projectFullScreenStyle}>

                <div style={constructionHomeStyles.sectionTitleRoot}>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                    <div style={constructionHomeStyles.sectionTitleMain}>{"PROJECTS"}</div>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                </div>

                <div style={constructionHomeStyles.projectFullScreenMinusTitleStyleMobile}>

                    <div style={constructionHomeStyles.cardRootStyle}>

                        <div style={constructionHomeStyles.multiCardWrapper}>

                            <div style={constructionHomeStyles.equalFlex}>
                                <Card
                                    style={constructionHomeStyles.projectsCardStyleMobile}
                                    containerStyle={isMobile && !isPortrait ? {display: 'flex', flexDirection: 'row', paddingBottom: 0 + 'px', } : undefined}
                                    onClick={()=>{this.setState({open: true, contentKey: "Project Delivery", })}}
                                    >
                                    <CardMedia
                                        style={isMobile ? isPortrait ? undefined : {flex: 1, } : undefined}
                                        overlay={<CardTitle title={"Project Delivery"} titleStyle={constructionHomeStyles.cardTitleStyleMobile} />}
                                        >
                                        <img src={'/org_apps.png'} style={constructionHomeStyles.cardImageStyle} />
                                    </CardMedia>
                                    <CardText style={isMobile ? isPortrait ? constructionHomeStyles.cardTextStyleMobile : constructionHomeStyles.cardTextStyleMobileLandscape : this.state.mw_1080 ? constructionHomeStyles.cardTextStyleMedium : constructionHomeStyles.cardTextStyle}>
                                        Bespoke tool extracting  technical & commercial data to automate
                                        reporting, scheduling and cost control.<br />
                                    </CardText>
                                </Card>
                            </div>

                            <div style={constructionHomeStyles.equalFlex}>
                                <Card
                                    style={constructionHomeStyles.projectsCardStyleMobile}
                                    containerStyle={isMobile && !isPortrait ? {display: 'flex', flexDirection: 'row', paddingBottom: 0 + 'px', } : undefined}
                                    onClick={()=>{this.setState({open: true, contentKey: "Gas Pipeline Construction", })}}
                                    >
                                    <CardMedia
                                        style={isMobile ? isPortrait ? undefined : {flex: 1, } : undefined}
                                        overlay={<CardTitle title={"Gas Pipeline Construction"} titleStyle={constructionHomeStyles.cardTitleStyleMobile} />}
                                        >
                                        <img src={'/pipeline.png'} style={constructionHomeStyles.cardImageStyle} />
                                    </CardMedia>
                                    <CardText style={isMobile ? isPortrait ? constructionHomeStyles.cardTextStyleMobile : constructionHomeStyles.cardTextStyleMobileLandscape : this.state.mw_1080 ? constructionHomeStyles.cardTextStyleMedium : constructionHomeStyles.cardTextStyle}>
                                        Automated project controls and quality reporting solution for CSG
                                        Gas pipeline construction project.<br />
                                    </CardText>
                                </Card>
                            </div>

                            <div style={constructionHomeStyles.equalFlex}>
                                <Card
                                    style={constructionHomeStyles.projectsCardStyleMobile}
                                    containerStyle={isMobile && !isPortrait ? {display: 'flex', flexDirection: 'row', paddingBottom: 0 + 'px', } : undefined}
                                    onClick={()=>{this.setState({open: true, contentKey: "Data Migration - AlphaMicro", })}}
                                    >
                                    <CardMedia
                                        style={isMobile ? isPortrait ? undefined : {flex: 1, } : undefined}
                                        overlay={<CardTitle title={"Data Migration - AlphaMicro"} titleStyle={constructionHomeStyles.cardTitleStyleMobile} />}
                                        >
                                        <img src={'/migration.png'} style={constructionHomeStyles.cardImageStyle} />
                                    </CardMedia>
                                    <CardText style={isMobile ? isPortrait ? constructionHomeStyles.cardTextStyleMobile : constructionHomeStyles.cardTextStyleMobileLandscape : this.state.mw_1080 ? constructionHomeStyles.cardTextStyleMedium : constructionHomeStyles.cardTextStyle}>
                                        Extraction and integration of manufacturing data from 40 year old system to
                                        integrate with new ERP.<br />
                                    </CardText>
                                </Card>
                            </div>

                            <div style={constructionHomeStyles.equalFlex}>
                                <Card
                                    style={constructionHomeStyles.projectsCardStyleMobile}
                                    containerStyle={isMobile && !isPortrait ? {display: 'flex', flexDirection: 'row', paddingBottom: 0 + 'px', } : undefined}
                                    onClick={()=>{this.setState({open: true, contentKey: "Increasing Productivity", })}}
                                    >
                                    <CardMedia
                                        style={isMobile ? isPortrait ? undefined : {flex: 1, } : undefined}
                                        overlay={<CardTitle title={"Increasing Productivity"} titleStyle={constructionHomeStyles.cardTitleStyleMobile} />}
                                        >
                                        <img src={'/productivity.png'} style={constructionHomeStyles.cardImageStyle} />
                                    </CardMedia>
                                    <CardText style={isMobile ? isPortrait ? constructionHomeStyles.cardTextStyleMobile : constructionHomeStyles.cardTextStyleMobileLandscape : this.state.mw_1080 ? constructionHomeStyles.cardTextStyleMedium : constructionHomeStyles.cardTextStyle}>
                                        Automated invoicing tool which completed cost control and
                                        financial modelling of a major energy project.<br />
                                    </CardText>
                                </Card>
                            </div>

                            <div style={constructionHomeStyles.equalFlex}>
                                <Card
                                    style={constructionHomeStyles.projectsCardStyleMobile}
                                    containerStyle={isMobile && !isPortrait ? {display: 'flex', flexDirection: 'row', paddingBottom: 0 + 'px', } : undefined}
                                    onClick={()=>{this.setState({open: true, contentKey: "Plant & Labour Analysis", })}}
                                    >
                                    <CardMedia
                                        style={isMobile ? isPortrait ? undefined : {flex: 1, } : undefined}
                                        overlay={<CardTitle title={"Plant & Labour Analysis"} titleStyle={constructionHomeStyles.cardTitleStyleMobile} />}
                                        >
                                        <img src={'/plant_labour.png'} style={constructionHomeStyles.cardImageStyle} />
                                    </CardMedia>
                                    <CardText style={isMobile ? isPortrait ? constructionHomeStyles.cardTextStyleMobile : constructionHomeStyles.cardTextStyleMobileLandscape : this.state.mw_1080 ? constructionHomeStyles.cardTextStyleMedium : constructionHomeStyles.cardTextStyle}>
                                        Forensic analysis of financial data in MYOB to resolve union dispute relating to
                                        labour rates.<br />
                                    </CardText>
                                </Card>
                            </div>

                            <div style={constructionHomeStyles.equalFlex}>
                                <Card
                                    style={constructionHomeStyles.projectsCardStyleMobile}
                                    containerStyle={isMobile && !isPortrait ? {display: 'flex', flexDirection: 'row', paddingBottom: 0 + 'px', } : undefined}
                                    onClick={()=>{this.setState({open: true, contentKey: "Land Access Application", })}}
                                    >
                                    <CardMedia
                                        style={isMobile ? isPortrait ? undefined : {flex: 1, } : undefined}
                                        overlay={<CardTitle title={"Land Access Application"} titleStyle={constructionHomeStyles.cardTitleStyleMobile} />}
                                        >
                                        <img src={'/land_access.png'} style={constructionHomeStyles.cardImageStyle} />
                                    </CardMedia>
                                    <CardText style={isMobile ? isPortrait ? constructionHomeStyles.cardTextStyleMobile : constructionHomeStyles.cardTextStyleMobileLandscape : this.state.mw_1080 ? constructionHomeStyles.cardTextStyleMedium : constructionHomeStyles.cardTextStyle}>
                                        Developed a land access application to
                                        manage land access permits and work flows.<br />
                                    </CardText>
                                </Card>
                            </div>

                        {isIOS ? (<a name="contact"></a>) : null}

                        </div>

                    </div>

                </div>
            </div>
        ) : (
            <div style={constructionHomeStyles.projectFullScreenStyle}>

                <div style={constructionHomeStyles.sectionTitleRoot}>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                    <div style={constructionHomeStyles.sectionTitleMain}>{"PROJECTS"}</div>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                </div>

                <div style={constructionHomeStyles.projectFullScreenMinusTitleStyle}>
                    <div style={constructionHomeStyles.cardRootStyle}>
                        <div style={constructionHomeStyles.multiCardWrapper}>

                            <div style={constructionHomeStyles.equalFlex}>
                                <Card
                                    style={this.state.mw_1080 ? constructionHomeStyles.projectsCardStyleMedium : constructionHomeStyles.projectsCardStyle}
                                    onClick={()=>{this.setState({open: true, contentKey: "Project Delivery", })}}
                                    >
                                    <CardMedia
                                        overlay={<CardTitle title={"Project Delivery"} titleStyle={this.state.mw_1080 ? constructionHomeStyles.projectCardTitleStyleMedium : constructionHomeStyles.cardTitleStyle} />}
                                        >
                                        <img src={'/org_apps.png'} style={constructionHomeStyles.cardImageStyle} />
                                    </CardMedia>
                                    <CardText style={constructionHomeStyles.projectsCardTextStyle}>
                                        Bespoke tool extracting  technical & commercial data to automate
                                        reporting, scheduling and cost control.<br />
                                    </CardText>
                                </Card>
                            </div>

                            <div style={constructionHomeStyles.equalFlex}>
                                <Card
                                    style={this.state.mw_1080 ? constructionHomeStyles.projectsCardStyleMedium : constructionHomeStyles.projectsCardStyle}
                                    onClick={()=>{this.setState({open: true, contentKey: "Gas Pipeline Construction", })}}
                                    >
                                    <CardMedia
                                        overlay={<CardTitle title={"Gas Pipeline Construction"} titleStyle={this.state.mw_1080 ? constructionHomeStyles.projectCardTitleStyleMedium : constructionHomeStyles.cardTitleStyle} />}
                                        >
                                        <img src={'/pipeline.png'} style={constructionHomeStyles.cardImageStyle} />
                                    </CardMedia>
                                    <CardText style={constructionHomeStyles.projectsCardTextStyle}>
                                        Automated project controls and quality reporting solution for CSG
                                        Gas pipeline construction project.<br />
                                    </CardText>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <div style={constructionHomeStyles.cardRootStyle}>
                        <div style={constructionHomeStyles.multiCardWrapper}>
                            <div style={constructionHomeStyles.equalFlex}>
                                <Card
                                    style={this.state.mw_1080 ? constructionHomeStyles.projectsCardStyleMedium : constructionHomeStyles.projectsCardStyle}
                                    onClick={()=>{this.setState({open: true, contentKey: "Data Migration - AlphaMicro", })}}
                                    >
                                    <CardMedia
                                        overlay={<CardTitle title={"Data Migration - AlphaMicro"} titleStyle={this.state.mw_1080 ? constructionHomeStyles.projectCardTitleStyleMedium : constructionHomeStyles.cardTitleStyle} />}
                                        >
                                        <img src={'/migration.png'} style={constructionHomeStyles.cardImageStyle} />
                                    </CardMedia>
                                    <CardText style={constructionHomeStyles.projectsCardTextStyle}>
                                        Extraction and integration of manufacturing data from 40 year old system to
                                        integrate with new ERP.<br />
                                    </CardText>
                                </Card>
                            </div>

                            <div style={constructionHomeStyles.equalFlex}>
                                <Card
                                    style={this.state.mw_1080 ? constructionHomeStyles.projectsCardStyleMedium : constructionHomeStyles.projectsCardStyle}
                                    onClick={()=>{this.setState({open: true, contentKey: "Increasing Productivity", })}}
                                    >
                                    <CardMedia
                                        overlay={<CardTitle title={"Increasing Productivity"} titleStyle={this.state.mw_1080 ? constructionHomeStyles.projectCardTitleStyleMedium : constructionHomeStyles.cardTitleStyle} />}
                                        >
                                        <img src={'/productivity.png'} style={constructionHomeStyles.cardImageStyle} />
                                    </CardMedia>
                                    <CardText style={constructionHomeStyles.projectsCardTextStyle}>
                                        Automated invoicing tool which completed cost control and
                                        financial modelling of a major energy project.<br />
                                    </CardText>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <div style={constructionHomeStyles.cardRootStyle}>
                        <div style={constructionHomeStyles.multiCardWrapper}>
                            <div style={constructionHomeStyles.equalFlex}>
                                <Card
                                    style={this.state.mw_1080 ? constructionHomeStyles.projectsCardStyleMedium : constructionHomeStyles.projectsCardStyle}
                                    onClick={()=>{this.setState({open: true, contentKey: "Plant & Labour Analysis", })}}
                                    >
                                    <CardMedia
                                        overlay={<CardTitle title={"Plant & Labour Analysis"} titleStyle={this.state.mw_1080 ? constructionHomeStyles.projectCardTitleStyleMedium : constructionHomeStyles.cardTitleStyle} />}
                                        >
                                        <img src={'/plant_labour.png'} style={constructionHomeStyles.cardImageStyle} />
                                    </CardMedia>
                                    <CardText style={constructionHomeStyles.projectsCardTextStyle}>
                                        Forensic analysis of financial data in MYOB to resolve union dispute relating to
                                        labour rates.<br />
                                    </CardText>
                                </Card>
                            </div>

                            <div style={constructionHomeStyles.equalFlex}>
                                <Card
                                    style={this.state.mw_1080 ? constructionHomeStyles.projectsCardStyleMedium : constructionHomeStyles.projectsCardStyle}
                                    onClick={()=>{this.setState({open: true, contentKey: "Land Access Application", })}}
                                    >
                                    <CardMedia
                                        overlay={<CardTitle title={"Land Access Application"} titleStyle={this.state.mw_1080 ? constructionHomeStyles.projectCardTitleStyleMedium : constructionHomeStyles.cardTitleStyle} />}
                                        >
                                        <img src={'/land_access.png'} style={constructionHomeStyles.cardImageStyle} />
                                    </CardMedia>
                                    <CardText style={constructionHomeStyles.projectsCardTextStyle}>
                                        Developed a land access application to
                                        manage land access permits and work flows.<br />
                                    </CardText>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        const textSection = this.state.sm ? (
            <div>
                <div style={constructionHomeStyles.contactInfoTextWrapperSmall}>
                    <div style={constructionHomeStyles.contactInfoTextContainer}>
                        <h1>Office</h1>
                        <p style={constructionHomeStyles.contactInfoText}>12 Dover Street</p>
                        <p style={constructionHomeStyles.contactInfoText}>Red Hill, 4059</p>
                    </div>
                    <div style={constructionHomeStyles.contactInfoTextContainer}>
                        <h1>Phone</h1>
                        <p style={constructionHomeStyles.contactInfoText}>+61 450 714 457</p>
                    </div>

                    <div style={constructionHomeStyles.contactInfoTextContainer}>
                        <h1>Email</h1>
                        <p style={constructionHomeStyles.contactInfoText}><a href={'mailto:james@constructagility.com'}>james@constructagility.com</a></p>
                    </div>
                </div>
            </div>
        ) : (
            <div>
                <div style={constructionHomeStyles.contactInfoTextWrapper}>
                    <div style={constructionHomeStyles.contactInfoTextContainer}>
                        <h1>Office</h1>
                        <p style={constructionHomeStyles.contactInfoText}>12 Dover Street</p>
                        <p style={constructionHomeStyles.contactInfoText}>Red Hill, 4059</p>
                    </div>
                </div>
                <div style={constructionHomeStyles.contactInfoTextWrapper}>
                    <div style={constructionHomeStyles.contactInfoTextContainer}>
                        <h1>Phone</h1>
                        <p style={constructionHomeStyles.contactInfoText}>+61 450 714 457</p>
                    </div>

                    <div style={constructionHomeStyles.contactInfoTextContainer}>
                        <h1>Email</h1>
                        <p style={constructionHomeStyles.contactInfoText}><a href={'mailto:james@constructagility.com'}>james@constructagility.com</a></p>
                    </div>
                </div>
            </div>
        );

        const contactSection = isMobile ? (
            <div style={constructionHomeStyles.contactSectionRoot}>

                <div style={constructionHomeStyles.greySectionTitleRoot}>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                    <div style={constructionHomeStyles.sectionTitleMain}>{"CONTACT US"}</div>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                </div>

                <div style={constructionHomeStyles.contactRootMobile}>

                    <div style={constructionHomeStyles.contactInfoRootMobile}>
                        <div style={constructionHomeStyles.contactInfoWrapper}>

                            <p style={constructionHomeStyles.contactFormTextMobile}>For any inquiries, questions or more information, please email us or complete the form below.</p>

                            <img
                                src={'/contact.png'}
                                style={constructionHomeStyles.contactInfoImageMobile}
                                />

                            <div style={constructionHomeStyles.contactInfoTextWrapperMobile}>
                                <div style={constructionHomeStyles.contactInfoTextContainer}>
                                    <h1 style={constructionHomeStyles.contactInfoTextHeader}>Office</h1>
                                    <p style={constructionHomeStyles.contactInfoTextMobile}>12 Dover Street</p>
                                    <p style={constructionHomeStyles.contactInfoTextMobile}>Red Hill, 4059</p>
                                </div>

                                <div style={constructionHomeStyles.contactInfoTextContainer}>
                                    <h1 style={constructionHomeStyles.contactInfoTextHeader}>Phone</h1>
                                    <p style={constructionHomeStyles.contactInfoTextMobile}>+61 450 714 457</p>
                                </div>

                                <div style={constructionHomeStyles.contactInfoTextContainer}>
                                    <h1 style={constructionHomeStyles.contactInfoTextHeader}>Email</h1>
                                    <p style={constructionHomeStyles.contactInfoTextMobile}><a href={'mailto:james@constructagility.com'}>james@constructagility.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={constructionHomeStyles.contactFormRoot}>
                        {/* <W2PForm
                            modelList={this.props.contactFormModel}
                            url={this.props.url}
                            formkey={this.props.formkey}
                            formname={this.props.formname}
                            vars={this.props.vars}
                            errors={this.props.errors}
                            form_ajax_type={'CREATE'}
                            form_button_title={'SEND MESSAGE'}
                            recaptchaKey={this.props.useRecaptcha ? '6LffNMMUAAAAAAihwBMRh0u1yP9sr8LmKCd-CO9J' : ''}
                            singleUsage={true}
                            singleUsageSuccessfulMessage={"Thanks for you message, We will be in touch soon!"}
                            rootStyle={constructionHomeStyles.w2pformRootStyle}
                            recaptchaStyle={constructionHomeStyles.w2pformRecaptchaMobileStyle}
                            namespace={'w2pform'}
                            /> */}
                    </div>

                </div>

                <div style={constructionHomeStyles.contactBanner} />

                {isIOS ? (<a name="blog"></a>) : null}

            </div>
        ) : (
            <div style={constructionHomeStyles.contactSectionRoot}>

                <div style={constructionHomeStyles.greySectionTitleRoot}>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                    <div style={constructionHomeStyles.sectionTitleMain}>{"CONTACT US"}</div>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                </div>

                <div style={constructionHomeStyles.contactRoot}>

                    <div style={constructionHomeStyles.contactFormRoot}>

                        <p style={constructionHomeStyles.contactFormText}>For any inquiries, questions or more information, please email us or complete the form below.</p>

                    </div>

                    <div style={constructionHomeStyles.contactInfoRoot}>
                        <div style={constructionHomeStyles.contactInfoWrapper}>
                            <img
                                src={'/contact.png'}
                                style={this.state.sm ? constructionHomeStyles.contactInfoImageSmall : constructionHomeStyles.contactInfoImage}
                                />

                            {textSection}
                        </div>
                    </div>

                </div>

                <div style={constructionHomeStyles.contactBanner} />

            </div>
        );

        const blogSection = isMobile ? (
            <div style={constructionHomeStyles.blogSectionRoot}>

                <div style={constructionHomeStyles.sectionTitleRoot}>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                    <div style={constructionHomeStyles.sectionTitleMain}>{"BLOG"}</div>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                </div>

                <div style={constructionHomeStyles.blogCardWrapper}>

                    <a name="blog_1"></a>
                    <div style={constructionHomeStyles.blogCardRootStyleMobile}>
                        <Card style={constructionHomeStyles.blogCardStyle}>
                            <CardMedia
                                overlay={<CardTitle title={"Meet the Founder"} subtitle={"March 16, " + currentYear} titleStyle={constructionHomeStyles.cardTitleStyleMobile} subtitleStyle={constructionHomeStyles.cardSubTitleStyleMobile} />}
                                >
                                <img src={'/about_us.png'} style={isPortrait ? constructionHomeStyles.cardImageStyle : constructionHomeStyles.cardImageStyleLandscape} />
                            </CardMedia>
                            <CardText style={constructionHomeStyles.cardTextStyleMobile}>
                                I was born in Ireland where I spent my childhood fishing with my dad and building things
                                in his work shed, I was always fascinated by how things worked, so becoming an engineer
                                was not a major surprise.  I played rugby, lots of xbox and golf. <br /><br />

                                I started Construct Agility in 2014, but the methodology behind the business had been
                                bubbling for years, through academia and my professional career.  I studied Electrical
                                & Electronic Engineering in Ireland, where I developed a fire-seeking, fire fighting
                                robot with room mapping capabilities.  I went on the work for a global engineering firm
                                in Building Services, but was drawn to solving everyday problems in the office with
                                practical solutions.  I built bespoke tools to automate the printing of drawings for
                                projects as well as creating software to check the designs of projects.  In 2010 I
                                returned to University and studies a Masters of Engineering.  My thesis was based on the
                                operation and energy output of wind farms.  I relocated to Australia in 2012 and arrived
                                in Brisbane.<br /><br />

                                I started working on the gas projects in Central Queensland, and found that the
                                construction delivery was hampered by onerous reporting requirements as well as poor
                                communications, challenging milestones and scope changes.  Most of the reporting, scope
                                changes, progress updates and cost management were completed manually, preventing the
                                project delivery team from having real-time updates to allow them to make data-driven
                                decisions in a project environment. <br /><br />

                                And so, I had found the solution I wanted to develop and bring to the market.  <br /><br />

                                For me the critical elements are: <br /><br />

                                - Bespoke, we develop the tool you need, no extra frills or lack of functionality and
                                integrate it into your existing systems <br /><br />

                                - Extracting commercial value from data - everyone is gathering data and are analysing
                                it in a historical sense, if you start analysing in real time, that will have a direct
                                impact on productivity and profitability <br /><br />

                                - Bringing big data solutions to the project environment.<br /><br />

                                I have been in Brisbane for 4 years, my business has been set up for 12 months and I am
                                delighted with my progress to date.  I am excited to see what the next 12 months will
                                bring. <br /><br />

                                Stay tuned for more blogs on my approach, latest trends in big data and updates on my
                                current projects. <br /><br />

                                <a href={"https://www.linkedin.com/in/james-o-driscoll-245a3030/"} target={"_blank"}>
                                James O'Driscoll </a> is the founder of Construct Agility, golf fanatic and data enthusiast.
                            </CardText>
                        </Card>
                    </div>

                    <a name="blog_2"></a>
                    <div style={constructionHomeStyles.blogCardRootStyleMobile}>
                        <Card style={constructionHomeStyles.blogCardStyle}>
                            <CardMedia
                                overlay={<CardTitle title={"Big Data & Heavy Vehicles"} subtitle={"January 11, " + currentYear}titleStyle={constructionHomeStyles.cardTitleStyleMobile} subtitleStyle={constructionHomeStyles.cardSubTitleStyleMobile} />}
                                >
                                <img src={'/trucks.png'} style={isPortrait ? constructionHomeStyles.cardImageStyle : constructionHomeStyles.cardImageStyleLandscape} />
                            </CardMedia>
                            <CardText style={constructionHomeStyles.cardTextStyleMobile}>
                                Heavy vehicle usage has increased steadily over the last decade, with most government
                                agencies in Australia recognising the at figures will double by 2030 to meet demand and
                                population growth.  This places an additional burden on the existing freight routes and
                                transport network and has a significant impact on road maintenance, safety,
                                infrastructure development, road life cycles and driver behaviours. <br /><br />

                                There is a significant amount of data being generated by various intelligent transport
                                systems, GPS/navigation systems, operator fleet management systems and from regulatory
                                and enforcement bodies.   This data has the capacity to transform how government manages
                                the transport network to provide a safer, fit for purpose infrastructure system as well
                                as providing valuable insights into driver behaviours, compliance with legislation and
                                predicting future trends and demands.<br /><br />

                                There are also some significant benefits for the heavy vehicle/transport industry as
                                they would be able to leverage the data with their insurers to reduce premiums with
                                evidence of high performing drivers, full compliance with legislation and a strong safe
                                record. <br /><br />

                                Some of the potential applications of big data analytics in the heavy vehicle arena include: <br /><br />

                                <b>Safety:</b> <br /><br />
                                From a safety perspective we are trying to detect unsafe driving patterns and events
                                using on-board sensor data from in-vehicle units.  As safety is a primary concern we
                                feel that it should feature heavily in any big data solution, an initial approach would
                                be giving the solution the ability to detect patterns like excessive breaking using
                                force detection.  Force detection is a means of detecting changes in acceleration and
                                angular rotation which can be used to detect movement patterns and sudden events. <br /><br />

                                An example of using force detection for a safety application came be taken from the
                                health-care industry for protecting staff in remote areas of hospitals.  A device known
                                as a dead man switch (DMS) is used to detect body movement of staff members using force
                                detection.  If a staff member is incapacitated, as there are drastic changes in body
                                movement, an alarm is immediately sent to hospital security to attend to the staff
                                member. <br /><br />

                                Construct Agility recently completed a mobile app to replicate the DMS to extend the use
                                of this safety feature to all staff using their smartphones.  This application is
                                currently being commercialised and has given us some great insights into the use of
                                force detection for safety as well as other applications.<br /><br />

                                Having the ability to detect these forces in heavy vehicles can be used to detect
                                commonalities from a safety perspective.  Detecting occurrences in the same location
                                could point a need to reduce speed limits at a jurisdictional level.  While at an
                                operator level detecting occurrences could point to behavioural issues where an
                                operator/driver was not adhering to safe driving practices. <br /><br />

                                <b>Congestion:</b> <br /><br />
                                Is the current road network being utilised to capacity?  Where do we need to invest in
                                new infrastructure?  Being able to answer questions like these will be possible and
                                allow Government to make data driven decisions when creating and adapting policy for the
                                future as well as practical operational usages around road maintenance, access and road
                                pricing. <br /><br />

                                <b>Road Usage - Pricing Model:</b> <br /><br />
                                Our vision is to incorporate the available data with other related transport department
                                data sources such as road type, geo-spatial data, road cost & design specifications and
                                combine these using a big data approach.  We can then calculate a cost associated with
                                travel on a specific section of road and which would allow regulatory bodies to
                                calculate a Mass-Distance-Location (MDL) price for each operator based on exact usage.
                                This data would give industry who participate in the program, an opportunity to pay fees
                                based on actual usage.  This would also be a feature to encourage more industry
                                application. <br /><br />

                                <b>Compliance with Regulation:</b> <br /><br />
                                The data collected, would be able to identify driver behaviour including speeding,
                                excessive breaking which are against regulation.  To increase industry participation,
                                it would be useful to discuss a discount scheme with insurers should the company’s fleet
                                be in the “high-performing” category.

                                <b>Future-proofing:</b> <br /><br />
                                The future of driverless cars, drone deliveries and other technology driven solutions
                                will change the transport network significantly.  The insights, data and analytics
                                captured from heavy and private vehicles should be used to drive policy & decision and
                                will make the integration with new technologies easier, safety and more effective. <br /><br />

                                <b>Road Maintenance:</b> <br /><br />
                                Analysing breaking behaviours of HV drivers will allow government to allocate a cost to
                                the repair and reflect this in a pricing structure.  It will also identify if
                                maintenance is required sooner that would be scheduled.  The opportunities in this area
                                are endless, a heat map of potential hot spots by comparing the core data with other
                                data sets (accident reports; temperatures to indicate issues with asphalt; HV turning
                                points on roads etc) would act as an early warning system for maintenance works. <br /><br />

                                <b>Targeted Education:</b> <br /><br />
                                Current driver information comes in survey format and reliability and accuracy is
                                questionable.  Once behaviours are being analysed, you can target the right education
                                campaigns to the right people in the right area, increasing effectiveness and return on
                                investment. <br /><br />
                            </CardText>
                        </Card>
                    </div>

                    <a name="blog_3"></a>
                    <div style={constructionHomeStyles.blogCardRootStyleMobile}>
                        <Card style={constructionHomeStyles.blogCardStyle}>
                            <CardMedia
                                overlay={<CardTitle title={"Excel Rant!"} subtitle={"May 29, " + currentYear} titleStyle={constructionHomeStyles.cardTitleStyleMobile} subtitleStyle={constructionHomeStyles.cardSubTitleStyleMobile} />}
                                >
                                <img src={'/bad_excel.png'} style={isPortrait ? constructionHomeStyles.cardImageStyle : constructionHomeStyles.cardImageStyleLandscape} />
                            </CardMedia>
                            <CardText style={constructionHomeStyles.cardTextStyleMobile}>
                                A collectiive gasp from the room when I declare that excel should be banned for used
                                beyond managing a household budget.  Engineers love their excel spreadsheets.  For
                                everything.  Cost control, budget management, scheduling projects, budgeting weddings
                                (pointless!), organising camping trips and everything in between.  While managing a
                                small budget is straightforward in Excel, the problems creep in for larger projects or
                                in multi-user environments.  Files regularly get corrupted, incorrect information is
                                entered and the data is compromised.  Most of those who are pro-excel readily agree that
                                there are (many) faults with Excel, they also display a strong reluctance to consider
                                changing to another solution. <br /><br />

                                In a project I worked on last year, I data-mined, managed and analysed 4000 invoices,
                                using an automated tool I developed (Read more here).  Prior to my involvement, the data
                                was being manually entered into an excel spreadsheet as well as SAP and Prism (a cost
                                management tool).  The data was being triple-handled with no value for the data owners,
                                just multiple manual processes.  During a project meeting, there was a lively discussion
                                as to whether there was an issue with their payment of suppliers as a number of payments
                                were over 100 days before being processed and no one could access any information to
                                establish what the situation was.  Using the data structure I had set up, I was able to
                                build a specific query to establish invoice issue dates against payment dates and
                                identify that the supplier was issuing the invoices almost 90 days after works were
                                completed and the delay sat with them not my client.  This might sound relatively simply
                                but there were 8 people in a room discussing this very point at length and a lack of
                                visibility across the data they were capturing (3 times!) meant they couldn't establish
                                a quick answer.<br /><br />

                                Excel should be, at best, a desktop tool for quick sums, analysis etc but should not be
                                the backbone of a project or cost management tool.  It is easily corruptible, prone to
                                human-error and inflexible for details analysis. <br /><br />

                                It's time to say goodbye to excel, the next generation is here.
                            </CardText>
                        </Card>
                    </div>

                    <a name="blog_4"></a>
                    <div style={constructionHomeStyles.blogCardRootStyleMobile}>
                        <Card style={constructionHomeStyles.blogCardStyle}>
                            <CardMedia
                                overlay={<CardTitle title={"More Tech, More Problems"} subtitle={"May 29, " + currentYear} titleStyle={constructionHomeStyles.cardTitleStyleMobile} subtitleStyle={constructionHomeStyles.cardSubTitleStyleMobile} />}
                                >
                                <img src={"/frustrated.png"} style={isPortrait ? constructionHomeStyles.cardImageStyle : constructionHomeStyles.cardImageStyleLandscape} />
                            </CardMedia>
                            <CardText style={constructionHomeStyles.cardTextStyleMobile}>
                                Many of my clients comment on how often they are sold a technology solution which will
                                make their employees lives significantly easier, however the opposite is often true.
                                Poor interconnectivity between corporate systems, heavy and multiple data entry points
                                require additional administration support, a lack of employee engagement with new
                                systems and a general suspicion that technology might not be all that it is cracked up
                                to be, is the feedback I get when speaking to clients about their previous technology
                                investments.<br /><br />

                                Why?  It seems that with more technology comes more problems.  How is it that in our
                                personal lives we manage multiple apps, social media accounts, dating profiles,
                                professional sites via multiple apps but we can't seem to replicate this in a business
                                setting.  Most clients, colleagues and friends complain about poor systems in their
                                workplace.<br /><br />

                                So what is the solution?<br /><br />

                                Stop buying off the shelf solutions that might fix a problem, because they also
                                introduce a host of new ones.  Start investing in your business by garnering visibility
                                across the business using your data and using this analysis to establish where you need
                                to invest and how.  Your technology should fit around your business, not force you to
                                fit your business around your technology. <br /><br />

                                Do you find that despite the increase in technology available, your technology
                                investments have been underwhelming in their results?
                            </CardText>
                        </Card>
                    </div>

                    <a name="blog_5"></a>
                    <div style={constructionHomeStyles.blogCardRootStyleMobile}>
                        <Card style={constructionHomeStyles.blogCardStyle}>
                            <CardMedia
                                overlay={<CardTitle title={"What is Big Data?"} subtitle={"May 29, " + currentYear} titleStyle={constructionHomeStyles.cardTitleStyleMobile} subtitleStyle={constructionHomeStyles.cardSubTitleStyleMobile} />}
                                >
                                <img src={'/data_science.png'} style={isPortrait ? constructionHomeStyles.cardImageStyle : constructionHomeStyles.cardImageStyleLandscape} />
                            </CardMedia>
                            <CardText style={constructionHomeStyles.cardTextStyleMobile}>
                                The term Big Data, is used to describe a collective of adjectives which accurately
                                capture what big data is. <br /><br />

                                1. Volume - Quite simply the amount of data being captured.  Most organistions have over
                                100 terabytes of data.  What value is that delivering currently? <br /><br />
                                2. Velocity - The speed at which the data must be captured, stored and analysed. <br /><br />
                                3. Variety - This refers to the various sources and structures in which data is received. <br /><br />
                                4. Veracity - refers to the reliability, variances and biases which may exist in data
                                sets and speaks directly to the accuracy and relevance of the data captured.<br /><br />

                                Basically, the easiest way to identify big data analytics is to think about Google ads.
                                You Google something about a wedding, once, and all the advertising you encounter
                                suddenly has a wedding flavour.  This is Google, capturing your search data, analysing
                                it and establishing that you have an interest in weddings, so they target all wedding
                                adverts to you.  Deploying big data tools in your business will provide insights, trends
                                and opportunities to improve, thus increasing productivity and profitability. <br /><br />

                                To achieve this, there are a couple of things that you have to consider:<br /><br />

                                - Is your data stored in a centralised location or will data mining be required to cleanse the data <br /><br />

                                - Do you know what visibility will provide most value to your business <br /><br />

                                - Have you identified the manual data processes or the double handling of data points? <br /><br />

                                Big data is a maths based solution which allows you to make data-driven decision in
                                real-time.  It is the next level of project and business management.  Most of the large
                                technology businesses and management consultancy firms are describing big data as the
                                next frontier in competitiveness, productivity and profitability. <br /><br />

                                So it is an exciting time to start thinking about your organisations' big data strategy
                                and seeing what value you can extract.  We are here to facilitate this for our clients.
                            </CardText>
                        </Card>

                    </div>

                </div>

            </div>
        ) : (
            <div style={constructionHomeStyles.blogSectionRoot}>

                <div style={constructionHomeStyles.sectionTitleRoot}>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                    <div style={constructionHomeStyles.sectionTitleMain}>{"BLOG"}</div>
                    <div style={constructionHomeStyles.sectionTitleSpacing} />
                </div>

                <div style={constructionHomeStyles.blogContainer}>

                    <div style={constructionHomeStyles.blogCardWrapper}>

                        <div style={constructionHomeStyles.blogCardRootStyle}>
                            <a name="blog_1"></a>
                            <Card style={constructionHomeStyles.blogCardStyle}>
                                <CardMedia
                                    overlay={<CardTitle title={"Meet the Founder"} subtitle={"March 16, " + currentYear} />}
                                    >
                                    <img src={'/about_us.png'} style={constructionHomeStyles.blogCardImageStyle} />
                                </CardMedia>
                                <CardText style={constructionHomeStyles.cardTextStyle}>
                                    I was born in Ireland where I spent my childhood fishing with my dad and building things
                                    in his work shed, I was always fascinated by how things worked, so becoming an engineer
                                    was not a major surprise.  I played rugby, lots of xbox and golf. <br /><br />

                                    I started Construct Agility in 2014, but the methodology behind the business had been
                                    bubbling for years, through academia and my professional career.  I studied Electrical
                                    & Electronic Engineering in Ireland, where I developed a fire-seeking, fire fighting
                                    robot with room mapping capabilities.  I went on the work for a global engineering firm
                                    in Building Services, but was drawn to solving everyday problems in the office with
                                    practical solutions.  I built bespoke tools to automate the printing of drawings for
                                    projects as well as creating software to check the designs of projects.  In 2010 I
                                    returned to University and studies a Masters of Engineering.  My thesis was based on the
                                    operation and energy output of wind farms.  I relocated to Australia in 2012 and arrived
                                    in Brisbane.<br /><br />

                                    I started working on the gas projects in Central Queensland, and found that the
                                    construction delivery was hampered by onerous reporting requirements as well as poor
                                    communications, challenging milestones and scope changes.  Most of the reporting, scope
                                    changes, progress updates and cost management were completed manually, preventing the
                                    project delivery team from having real-time updates to allow them to make data-driven
                                    decisions in a project environment. <br /><br />

                                    And so, I had found the solution I wanted to develop and bring to the market.  <br /><br />

                                    For me the critical elements are: <br /><br />

                                    - Bespoke, we develop the tool you need, no extra frills or lack of functionality and
                                    integrate it into your existing systems <br /><br />

                                    - Extracting commercial value from data - everyone is gathering data and are analysing
                                    it in a historical sense, if you start analysing in real time, that will have a direct
                                    impact on productivity and profitability <br /><br />

                                    - Bringing big data solutions to the project environment.<br /><br />

                                    I have been in Brisbane for 4 years, my business has been set up for 12 months and I am
                                    delighted with my progress to date.  I am excited to see what the next 12 months will
                                    bring. <br /><br />

                                    Stay tuned for more blogs on my approach, latest trends in big data and updates on my
                                    current projects. <br /><br />

                                    <a href={"https://www.linkedin.com/in/james-o-driscoll-245a3030/"} target={"_blank"}>
                                    James O'Driscoll </a> is the founder of Construct Agility, golf fanatic and data enthusiast.
                                </CardText>
                            </Card>
                        </div>

                        <div style={constructionHomeStyles.blogCardRootStyle}>
                            <a name="blog_2"></a>
                            <Card style={constructionHomeStyles.blogCardStyle}>
                                <CardMedia
                                    overlay={<CardTitle title={"Big Data & Heavy Vehicles"} subtitle={"January 11, " + currentYear} />}
                                    >
                                    <img src={'/trucks.png'} style={constructionHomeStyles.blogCardImageStyle} />
                                </CardMedia>
                                <CardText style={constructionHomeStyles.cardTextStyle}>
                                    Heavy vehicle usage has increased steadily over the last decade, with most government
                                    agencies in Australia recognising the at figures will double by 2030 to meet demand and
                                    population growth.  This places an additional burden on the existing freight routes and
                                    transport network and has a significant impact on road maintenance, safety,
                                    infrastructure development, road life cycles and driver behaviours. <br /><br />

                                    There is a significant amount of data being generated by various intelligent transport
                                    systems, GPS/navigation systems, operator fleet management systems and from regulatory
                                    and enforcement bodies.   This data has the capacity to transform how government manages
                                    the transport network to provide a safer, fit for purpose infrastructure system as well
                                    as providing valuable insights into driver behaviours, compliance with legislation and
                                    predicting future trends and demands.<br /><br />

                                    There are also some significant benefits for the heavy vehicle/transport industry as
                                    they would be able to leverage the data with their insurers to reduce premiums with
                                    evidence of high performing drivers, full compliance with legislation and a strong safe
                                    record. <br /><br />

                                    Some of the potential applications of big data analytics in the heavy vehicle arena include: <br /><br />

                                    <b>Safety:</b> <br /><br />
                                    From a safety perspective we are trying to detect unsafe driving patterns and events
                                    using on-board sensor data from in-vehicle units.  As safety is a primary concern we
                                    feel that it should feature heavily in any big data solution, an initial approach would
                                    be giving the solution the ability to detect patterns like excessive breaking using
                                    force detection.  Force detection is a means of detecting changes in acceleration and
                                    angular rotation which can be used to detect movement patterns and sudden events. <br /><br />

                                    An example of using force detection for a safety application came be taken from the
                                    health-care industry for protecting staff in remote areas of hospitals.  A device known
                                    as a dead man switch (DMS) is used to detect body movement of staff members using force
                                    detection.  If a staff member is incapacitated, as there are drastic changes in body
                                    movement, an alarm is immediately sent to hospital security to attend to the staff
                                    member. <br /><br />

                                    Construct Agility recently completed a mobile app to replicate the DMS to extend the use
                                    of this safety feature to all staff using their smartphones.  This application is
                                    currently being commercialised and has given us some great insights into the use of
                                    force detection for safety as well as other applications.<br /><br />

                                    Having the ability to detect these forces in heavy vehicles can be used to detect
                                    commonalities from a safety perspective.  Detecting occurrences in the same location
                                    could point a need to reduce speed limits at a jurisdictional level.  While at an
                                    operator level detecting occurrences could point to behavioural issues where an
                                    operator/driver was not adhering to safe driving practices. <br /><br />

                                    <b>Congestion:</b> <br /><br />
                                    Is the current road network being utilised to capacity?  Where do we need to invest in
                                    new infrastructure?  Being able to answer questions like these will be possible and
                                    allow Government to make data driven decisions when creating and adapting policy for the
                                    future as well as practical operational usages around road maintenance, access and road
                                    pricing. <br /><br />

                                    <b>Road Usage - Pricing Model:</b> <br /><br />
                                    Our vision is to incorporate the available data with other related transport department
                                    data sources such as road type, geo-spatial data, road cost & design specifications and
                                    combine these using a big data approach.  We can then calculate a cost associated with
                                    travel on a specific section of road and which would allow regulatory bodies to
                                    calculate a Mass-Distance-Location (MDL) price for each operator based on exact usage.
                                    This data would give industry who participate in the program, an opportunity to pay fees
                                    based on actual usage.  This would also be a feature to encourage more industry
                                    application. <br /><br />

                                    <b>Compliance with Regulation:</b> <br /><br />
                                    The data collected, would be able to identify driver behaviour including speeding,
                                    excessive breaking which are against regulation.  To increase industry participation,
                                    it would be useful to discuss a discount scheme with insurers should the company’s fleet
                                    be in the “high-performing” category.

                                    <b>Future-proofing:</b> <br /><br />
                                    The future of driverless cars, drone deliveries and other technology driven solutions
                                    will change the transport network significantly.  The insights, data and analytics
                                    captured from heavy and private vehicles should be used to drive policy & decision and
                                    will make the integration with new technologies easier, safety and more effective. <br /><br />

                                    <b>Road Maintenance:</b> <br /><br />
                                    Analysing breaking behaviours of HV drivers will allow government to allocate a cost to
                                    the repair and reflect this in a pricing structure.  It will also identify if
                                    maintenance is required sooner that would be scheduled.  The opportunities in this area
                                    are endless, a heat map of potential hot spots by comparing the core data with other
                                    data sets (accident reports; temperatures to indicate issues with asphalt; HV turning
                                    points on roads etc) would act as an early warning system for maintenance works. <br /><br />

                                    <b>Targeted Education:</b> <br /><br />
                                    Current driver information comes in survey format and reliability and accuracy is
                                    questionable.  Once behaviours are being analysed, you can target the right education
                                    campaigns to the right people in the right area, increasing effectiveness and return on
                                    investment. <br /><br />
                                </CardText>
                            </Card>
                        </div>

                        <div style={constructionHomeStyles.blogCardRootStyle}>
                            <a name="blog_3"></a>
                            <Card style={constructionHomeStyles.blogCardStyle}>
                                <CardMedia
                                    overlay={<CardTitle title={"Excel Rant!"} subtitle={"May 29, " + currentYear} />}
                                    >
                                    <img src={'/bad_excel.png'} style={constructionHomeStyles.blogCardImageStyle} />
                                </CardMedia>
                                <CardText style={constructionHomeStyles.cardTextStyle}>
                                    A collectiive gasp from the room when I declare that excel should be banned for used
                                    beyond managing a household budget.  Engineers love their excel spreadsheets.  For
                                    everything.  Cost control, budget management, scheduling projects, budgeting weddings
                                    (pointless!), organising camping trips and everything in between.  While managing a
                                    small budget is straightforward in Excel, the problems creep in for larger projects or
                                    in multi-user environments.  Files regularly get corrupted, incorrect information is
                                    entered and the data is compromised.  Most of those who are pro-excel readily agree that
                                    there are (many) faults with Excel, they also display a strong reluctance to consider
                                    changing to another solution. <br /><br />

                                    In a project I worked on last year, I data-mined, managed and analysed 4000 invoices,
                                    using an automated tool I developed (Read more here).  Prior to my involvement, the data
                                    was being manually entered into an excel spreadsheet as well as SAP and Prism (a cost
                                    management tool).  The data was being triple-handled with no value for the data owners,
                                    just multiple manual processes.  During a project meeting, there was a lively discussion
                                    as to whether there was an issue with their payment of suppliers as a number of payments
                                    were over 100 days before being processed and no one could access any information to
                                    establish what the situation was.  Using the data structure I had set up, I was able to
                                    build a specific query to establish invoice issue dates against payment dates and
                                    identify that the supplier was issuing the invoices almost 90 days after works were
                                    completed and the delay sat with them not my client.  This might sound relatively simply
                                    but there were 8 people in a room discussing this very point at length and a lack of
                                    visibility across the data they were capturing (3 times!) meant they couldn't establish
                                    a quick answer.<br /><br />

                                    Excel should be, at best, a desktop tool for quick sums, analysis etc but should not be
                                    the backbone of a project or cost management tool.  It is easily corruptible, prone to
                                    human-error and inflexible for details analysis. <br /><br />

                                    It's time to say goodbye to excel, the next generation is here.
                                </CardText>
                            </Card>
                        </div>

                        <div style={constructionHomeStyles.blogCardRootStyle}>
                            <a name="blog_4"></a>
                            <Card style={constructionHomeStyles.blogCardStyle}>
                                <CardMedia
                                    overlay={<CardTitle title={"More Tech, More Problems"} subtitle={"May 29, " + currentYear} />}
                                    >
                                    <img src={"/frustrated.png"} style={constructionHomeStyles.blogCardImageStyle} />
                                </CardMedia>
                                <CardText style={constructionHomeStyles.cardTextStyle}>
                                    Many of my clients comment on how often they are sold a technology solution which will
                                    make their employees lives significantly easier, however the opposite is often true.
                                    Poor interconnectivity between corporate systems, heavy and multiple data entry points
                                    require additional administration support, a lack of employee engagement with new
                                    systems and a general suspicion that technology might not be all that it is cracked up
                                    to be, is the feedback I get when speaking to clients about their previous technology
                                    investments.<br /><br />

                                    Why?  It seems that with more technology comes more problems.  How is it that in our
                                    personal lives we manage multiple apps, social media accounts, dating profiles,
                                    professional sites via multiple apps but we can't seem to replicate this in a business
                                    setting.  Most clients, colleagues and friends complain about poor systems in their
                                    workplace.<br /><br />

                                    So what is the solution?<br /><br />

                                    Stop buying off the shelf solutions that might fix a problem, because they also
                                    introduce a host of new ones.  Start investing in your business by garnering visibility
                                    across the business using your data and using this analysis to establish where you need
                                    to invest and how.  Your technology should fit around your business, not force you to
                                    fit your business around your technology. <br /><br />

                                    Do you find that despite the increase in technology available, your technology
                                    investments have been underwhelming in their results?
                                </CardText>
                            </Card>
                        </div>

                        <div style={constructionHomeStyles.blogCardRootStyle}>
                            <a name="blog_5"></a>
                            <Card style={constructionHomeStyles.blogCardStyle}>
                                <CardMedia
                                    overlay={<CardTitle title={"What is Big Data?"} subtitle={"May 29, " + currentYear} />}
                                    >
                                    <img src={'/data_science.png'} style={constructionHomeStyles.blogCardImageStyle} />
                                </CardMedia>
                                <CardText style={constructionHomeStyles.cardTextStyle}>
                                    The term Big Data, is used to describe a collective of adjectives which accurately
                                    capture what big data is. <br /><br />

                                    1. Volume - Quite simply the amount of data being captured.  Most organistions have over
                                    100 terabytes of data.  What value is that delivering currently? <br /><br />
                                    2. Velocity - The speed at which the data must be captured, stored and analysed. <br /><br />
                                    3. Variety - This refers to the various sources and structures in which data is received. <br /><br />
                                    4. Veracity - refers to the reliability, variances and biases which may exist in data
                                    sets and speaks directly to the accuracy and relevance of the data captured.<br /><br />

                                    Basically, the easiest way to identify big data analytics is to think about Google ads.
                                    You Google something about a wedding, once, and all the advertising you encounter
                                    suddenly has a wedding flavour.  This is Google, capturing your search data, analysing
                                    it and establishing that you have an interest in weddings, so they target all wedding
                                    adverts to you.  Deploying big data tools in your business will provide insights, trends
                                    and opportunities to improve, thus increasing productivity and profitability. <br /><br />

                                    To achieve this, there are a couple of things that you have to consider:<br /><br />

                                    - Is your data stored in a centralised location or will data mining be required to cleanse the data <br /><br />

                                    - Do you know what visibility will provide most value to your business <br /><br />

                                    - Have you identified the manual data processes or the double handling of data points? <br /><br />

                                    Big data is a maths based solution which allows you to make data-driven decision in
                                    real-time.  It is the next level of project and business management.  Most of the large
                                    technology businesses and management consultancy firms are describing big data as the
                                    next frontier in competitiveness, productivity and profitability. <br /><br />

                                    So it is an exciting time to start thinking about your organisations' big data strategy
                                    and seeing what value you can extract.  We are here to facilitate this for our clients.
                                </CardText>
                            </Card>

                        </div>

                    </div>

                    <div style={constructionHomeStyles.blogMenuRoot}>
                        <List style={constructionHomeStyles.blogListStyle}>
                            <Subheader style={constructionHomeStyles.contentStyle}>Blog Posts</Subheader>
                            <Divider inset={false} />
                            <ListItem
                                leftAvatar={<Avatar src={"/about_us.png"} />}
                                primaryText={"Meet the Founder"}
                                secondaryText={"March 16, " + currentYear}
                                href={"#blog_1"}
                                />
                            <Divider inset={false} />
                            <ListItem
                                leftAvatar={<Avatar src={"/trucks.png"} />}
                                primaryText={"Big Data & Heavy Vehicles"}
                                secondaryText={"January 11, " + currentYear}
                                href={"#blog_2"}
                                />
                            <Divider inset={false} />
                            <ListItem
                                leftAvatar={<Avatar src={"/bad_excel.png"} />}
                                primaryText={"Excel Rant!"}
                                secondaryText={"May 29, " + currentYear}
                                href={"#blog_3"}
                                />
                            <Divider inset={false} />
                            <ListItem
                                leftAvatar={<Avatar src={"/frustrated.png"} />}
                                primaryText={"More Tech, More Problems"}
                                secondaryText={"May 29, " + currentYear}
                                href={"#blog_4"}
                                />
                            <Divider inset={false} />
                            <ListItem
                                leftAvatar={<Avatar src={"/data_science.png"} />}
                                primaryText={"What is Big Data?"}
                                secondaryText={"May 29, " + currentYear}
                                href={"#blog_5"}
                                />
                            <Divider inset={false} />
                        </List>
                    </div>
                </div>
            </div>
        );

        return (
            <MuiThemeProvider muiTheme={muiTheme}>
                <div style={constructionHomeStyles.containerStyle}>

                    {this.state.open ? projectsDialog : null}

                    {isIOS ? (<a name="home"></a>) : null}

                    {toolbar}

                    <div
                        id={'bodyContent' + this.state.uid}
                        style={constructionHomeStyles.bodyContent}
                        >

                        {!isIOS ? (<a name="home"></a>) : null}

                        {homeSection}

                        {!isIOS ? (<a name="services"></a>) : null}

                        {serviceSection}

                        {!isIOS ? (<a name="about_us"></a>) : null}

                        {aboutSection}

                        {!isIOS ? (<a name="projects"></a>) : null}

                        {projectSection}

                        {!isIOS ? (<a name="contact"></a>) : null}

                        {contactSection}

                        {!isIOS ? (<a name="blog"></a>) : null}

                        {blogSection}
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}
